import React, { useEffect, useState } from "react";
import { NFTContractDetails } from "../../../services/NFTCreation.service";
import styles from "./Section2.module.scss";
function Section2() {
  const [NFTsInfo, setNFTsInfo] = useState({
    _NFTsold: "0",
    _availableForAuction: "0",
    _totalSupply: "0",
  });
  const NFTsAsync = async () => {
    

    const res = await NFTContractDetails();
    setNFTsInfo(res);
  };
  useEffect(() => {
    NFTsAsync();
  }, []);
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.flexRow}>
          <div className={styles.nftItems}>
            <div className={styles.heading}>NFTs Issued </div>
            <div className={styles.amountDiv}>
              <div className={styles.amount}>{NFTsInfo._totalSupply || 0}</div>
              {/* <div className={styles.percent}>+2.5%</div> */}
            </div>
            <div className={styles.comparedTo}>
              {/* Compared to ($21340 last week) */}
            </div>
            {/* <div className={styles.imgDiv}>
              <img alt="img" src="/assets/chart.svg" />
            </div> */}
          </div>
          <div className={styles.nftItems}>
            <div className={styles.heading}>NFTs Sold </div>
            <div className={styles.amountDiv}>
              <div className={styles.amount}>{NFTsInfo._NFTsold || 0}</div>
              {/* <div className={styles.percent}>+2.5%</div> */}
            </div>
            <div className={styles.comparedTo}>
              {/* Compared to ($21340 last week) */}
            </div>
            {/* <div className={styles.imgDiv}>
              <img alt="img" src="/assets/chart.svg" />
            </div> */}
          </div>
          <div className={styles.nftItems}>
            <div className={styles.heading}>NFTs On DUNK Auction</div>
            <div className={styles.amountDiv}>
              <div className={styles.amount}>
                {NFTsInfo._availableForAuction || 0}
              </div>
              {/* <div className={styles.percent}>+2.5%</div> */}
            </div>
            <div className={styles.comparedTo}>
              {/* Compared to ($21340 last week) */}
            </div>
            {/* <div className={styles.imgDiv}>
              <img alt="img" src="/assets/chart.svg" />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
export default Section2;
