import React from "react";
import styles from "./Section5.module.scss";
function Section5() {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.flexRow}>
          <div className={styles.flexCol}>
            <div className={styles.howToUse}>
              <div className={styles.nftItems}>
                {/* <pre className={styles.howDunk}>
                How Dunk
              </pre> */}
                <pre className={styles.headingDiv}>
                  Why
                  <div className={styles.dunk}> DUNK</div> ?
                </pre>
                <div className={styles.description}>
                  With the use of Blockchain Technology and Key Strategic
                  Partners, we have created a borderless transparent and
                  decentralized Resale Platform.
                  {/* <div className={styles.outOfStock}>
                    We are with Out of Stock.
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.flexCol}>
            <div className={styles.nftItems}>
              <div className={styles.imgDiv}>
                <img alt="img" src="/assets/fast-buy-sell.svg" />
              </div>
              <div className={styles.heading}>
                Enjoy Bid and Sells with a Low Fee
              </div>
              <div className={styles.description}>
                Using NFT technology, Transactions on DUNK are safe, transparent
                and fast resulting in lower sales fees than any other resale
                platform
              </div>
            </div>

            <div className={styles.nftItems}>
              <div className={styles.imgDiv}>
                <img alt="img" src="/assets/o2o.svg" />
              </div>
              <div className={styles.heading}>O2O SERVICE</div>
              <div className={styles.description}>
                As our Core Partner, Out of Stock (OOS), operates offline resale
                stores in locations such as Lotte Department Store - DUNK
                Platform will provide 020( Online to Offline) service with OOS
              </div>
            </div>
          </div>
          <div className={styles.flexCol}>
            <div className={styles.nftItems}>
              <div className={styles.imgDiv}>
                <img alt="img" src="/assets/zero-fake.svg" />
              </div>
              <div className={styles.heading}>Fake-Zero Technology</div>
              <div className={styles.description}>
                Using NFT technology and Proffessional Authentication Service
                Providers, every DUNK is authenticated with Transparent and
                Immutable Data
              </div>
            </div>

            <div className={styles.nftItems}>
              <div className={styles.imgDiv}>
                <img alt="img" src="/assets/additional-services.svg" />
              </div>
              <div className={styles.heading}>Additional Services</div>
              <div className={styles.description}>
                From Auction to Staking, Lotteries and Community - You can enjoy
                our services more than just BUY&SELL!
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Section5;
