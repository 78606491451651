import React from "react";
import styles from "./Auth.module.scss";
import SignIn from "./SignIn/SignIn";
import SignUp from "./SignUp/SignUp";
import { initializeWeb3 } from "../../services/web3.service";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

export default function Auth() {
  let { id } = useParams();
  const signUpErrorMessage = useSelector((state) => state.auth.signUpErrorMessage);

  const {
    selected: { address, isConnected },
  } = useSelector((state) => state.wallet);
  const connectToWallet = async (value) => {
      
    // props.onConnected();
    if (!isConnected) {
      await initializeWeb3(value).catch((e) => {
        console.log(e);
      });
    } else {
      return;
    }
  };
  console.log(id);
  return (
    <div className={styles.wrapper}>
      <div className={styles.flexRow}>
        <div className={styles.imgDiv}>
          <img
            src={id === "signUp" ? "/assets/signUp.png" : "/assets/signIn.png"}
          />
        </div>
        <div className={styles.childDiv}>
          {id === "signUp" ? (
            <SignUp
              connectToWallet={connectToWallet}
              address={address}
              isConnected={isConnected}
              errorMessage={signUpErrorMessage}
            />
          ) : (
            <SignIn
              connectToWallet={connectToWallet}
              address={address}
              isConnected={isConnected}
            />
          )}
        </div>
      </div>
    </div>
  );
}
