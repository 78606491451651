import React from "react";
import { useHistory } from "react-router-dom";
import styles from "./Section7.module.scss";
function Section7() {
  const history = useHistory();
  const handleClick = () => {
    window.open('https://dunkexchange.medium.com/');
    // window.location.href = 'https://dunkexchange.medium.com/';
    // history.push("https://dunkexchange.medium.com/");
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.colorDiv}>
          <div className={styles.howToUse}>
            <h4>Newsletter</h4>
            <pre className={styles.headingDiv}>
              Subscribe
              <div className={styles.dunk}> DUNK</div>
            </pre>
            <div className={styles.description}>For our future updates</div>
            <div className={styles.passwordField}>
              <input
                type="text"
                placeholder="Your Email"
                name="repeatPassword"
                className={`${styles.blueField}`}
              />
              <div className={styles.imgDiv}>
                <img
                  src="/assets/Path 345.svg"
                  className={styles.passwordImg}
                />
              </div>
            </div>
          </div>
          <div className={styles.knowMore}>
            <h4 onClick={handleClick}>Want to know more about DUNK?</h4>
            <img src="/assets/Path 429.svg" />
          </div>
        </div>
      </div>
    </div>
  );
}
export default Section7;
