import React, { useEffect } from "react";
import DunkAuction from "../components/DunkAuction/DunkAuction";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
// import ProductDetails from "../components/ProductDetails/ProductDetails";
import Section1 from "../components/__common/Section1/Section1";
import Section7 from "../components/LandingPage/Section7/Section7";
import Section3 from "../components/__common/Section3/Section3";
import NftDetails from "../components/__common/NftDetails/NftDetails";

export default function ProductDetailsPage() {
  useEffect(()=>{
    window.scrollTo({ top: 0, behavior: 'smooth' })
},[])
  return (
    <>
      <Header />
      <NftDetails />
      <Section7 />
      <Footer />
    </>
  );
}
