import React from 'react';
import DunkCommunity from '../components/DunkCommunity/DunkCommunity';
import Section1 from "../components/__common/Section1/Section1";
import Section7 from "../components/LandingPage/Section7/Section7";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";

const DunkCommunityPage = () => {
    return(
        <div>
         <Header />
         <Section1 
            heading="DUNK Community"
            features="COMING SOON!"
         />
         <DunkCommunity />
         <Section7 />
         <Footer />
        </div>
    )
}

export default DunkCommunityPage;