import { combineReducers } from "redux";
import authReducer from "./authReducer";
import { walletReducer } from "./WalletReducer";

const rootReducer = combineReducers({
  wallet: walletReducer,
  auth:authReducer
});
export { rootReducer };
export type RootState = ReturnType<typeof rootReducer>;
