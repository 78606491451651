import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import { rootReducer } from "./Index";
// import { composeWithDevTools } from "redux-devtools-extension";
import { verifyAuth } from "../actions/authActions";

const configureStore = () => {
  const middleware = [thunk];
  const store = createStore(rootReducer, applyMiddleware(...middleware));
  store.dispatch(verifyAuth());
  return store;
};

export default configureStore;
