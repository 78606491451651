import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { AuctionTypes } from "../../constants";
import instance from "../../utils/helper/http.helper";
import Card from "../DunkAuction/Card";
import SimpleSlider from "../__common/SimpleSlider/SimpleSlider";
import styles from "./MyNFTs.module.scss";

export default function MyNFTs() {
  const [auctionType, setAuctionType] = useState("");
  const {
    selected: { address, isConnected },
  } = useSelector((state) => state.wallet);
  const [data, setData] = useState([]);
  const [otherData, setOtherData] = useState([]);
  const handleAuctionLike = async (id,address) => {
    
    const res = await instance.post(`requests/like/${id}`, {
      address: address,
    });
    const index = data.findIndex(obj => obj._id === id);
    let newArray=[...data]
    newArray[index]=res.data.data
    setData([...newArray]);
  };
  const getApproved = async () => {
    let res2;
    
    if (auctionType === "") {
      res2 = await instance.get(`requests/my-nfts?status=approved`);
    } else {
      res2 = await instance.get(
        `/requests/my-nfts?status=approved&auction=${auctionType}`
      );
    }
    return res2;
  };
  const getOther = async () => {
    let res2;
    res2 = await instance.get(`/requests/my-nfts`);
    return res2;
  };
  const getNFTs = async () => {
    const others = await getOther();
    setOtherData(others.data.data);
    const approved = await getApproved();
    console.log(others.data.data);

    setData(approved.data.data);
  };
  useEffect(() => {
    getNFTs();
  }, [auctionType]);
  const clipping = (string) => {
    if (string.length > 20) {
      const new_string = string.substring(0, 20) + ".....";
      return new_string;
    }
    return string;
  };

  const history = useHistory();

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <SimpleSlider request={otherData} />
        <div className={styles.flexRow}>
          <pre className={styles.flexHeading}>
            My
            <div className={styles.strokedHeading}> NFTs</div>
          </pre>
          <div className={styles.flexFilters}>
            <div
              className={`${styles.basicFilters} ${
                auctionType === "" ? styles.active : ""
              }`}
              onClick={() => setAuctionType("")}
            >
              All
            </div>
            <div
              className={`${styles.basicFilters} ${
                auctionType === AuctionTypes.on ? styles.active : ""
              }`}
              onClick={() => setAuctionType(AuctionTypes.on)}
            >
              On Auction
            </div>
            <div
              className={`${styles.basicFilters} ${
                auctionType === AuctionTypes.off ? styles.active : ""
              }`}
              onClick={() => setAuctionType(AuctionTypes.off)}
            >
              Off Auction
            </div>
          </div>
        </div>
        <div className={styles.flexCard}>
          {data && data.length > 0 ? (
            data.map((item, i) => (
              <Card item={item} myNFTs={"/myNFTs-details/"} handleLike={handleAuctionLike}/>
              // <div
              //   className={styles.card}
              //   onClick={() => history.push(`/myNFTs-details/${item._id}`)}
              //   key={i}
              // >
              //   <div className={styles.imgDiv}>
              //     <img
              //       alt="img"
              //       src={`${process.env.REACT_APP_BASE_URL}/requests/file/${item.image[0].folderName}/${item.image[0].name}`}
              //     />
              //   </div>
              //   <div className={styles.flexROw}>
              //     <div className={styles.title}>{item.modelName}</div>
              //     <div className={styles.userDiv}>
              //       <div className={styles.owner}>Owner</div>
              //       <div className={styles.userName}>
              //         ({item.ownerAddress.substring(0, 7) + ".."})
              //       </div>
              //     </div>
              //   </div>

              // </div>
            ))
          ) : (
            <div style={{ textAlign: "center", width: "100%" }}>
              No data found
            </div>
          )}
          {/* <div className={styles.card} onClick={() => history.push("/authentication")}>
            <div className={styles.imgDiv}>
              <img alt="img" src="/assets/Mask3.png" />
              <div className={styles.redeemable}>Redeemable</div>
              <div className={styles.reverse}>$REVERSE</div>
            </div>
            <div className={styles.flexROw}>
              <div className={styles.title}>2021 ALL-STAR GAME</div>
              <div className={styles.userDiv}>
                <div className={styles.profileImg}>
                  <img alt="img" src="/assets/user.svg" />
                </div>
                <div className={styles.userName}>Kevin Woodkin</div>
              </div>
            </div>
            <div className={styles.priceDanger}>USD $229.00</div>
            <div className={styles.soldOut}>4,0420 sold out</div>
          </div>
         */}
        </div>
      </div>
    </div>
  );
}
