import React, { useEffect } from "react";
import DunkAuction from "../components/DunkAuction/DunkAuction";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import CreateDunkLoan from "../components/CreateDunkLoan/CreateDunkLoan";
import Section1 from "../components/__common/Section1/Section1";
import Section7 from "../components/LandingPage/Section7/Section7";
import Section3 from "../components/__common/Section3/Section3";

export default function CreateDunkLoanPage() {
  useEffect(()=>{
    window.scrollTo({ top: 0, behavior: 'smooth' })
},[])
  return (
    <>
      <Header />
      <Section1
        heading="DUNK Lottery"
        features="Win a chance to receive special NFT with DUNK tokens"
      />
      <CreateDunkLoan />
      <Section7 />
      <Footer />
    </>
  );
}
