import React from "react";
import styles from "./Section3.module.scss";
function Section3() {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.imgContainer}>
          <div className={styles.imgDiv}>
            <img alt="img" src="/assets/cv1363-001-01_0.svg" />
          </div>
        </div>
        <div className={styles.howToUse}>
          <div className={styles.nftItems}>
            <pre className={styles.headingDiv}>
              How to use
              <div className={styles.dunk}> DUNK</div> ?
            </pre>
            <div className={styles.description}>
              Enjoy 0% Fake, 100% Safe, Sneaker Resale Platform
            </div>
          </div>
        </div>
        <div className={styles.flexRow}>
          <div className={styles.nftItems}>
            <div className={styles.steps}>1</div>
            <div className={styles.heading}>
              Send Sneakers to DUNK to issue NFTs
            </div>
            <div className={styles.description}>
              Select the item you want to create into a NFT, make a request and
              then ship the good to your local DUNK Warehouse
            </div>
          </div>

          <div className={styles.nftItems}>
            <div className={styles.steps}>2</div>
            <div className={styles.heading}>Recieve your NFT Sneakers</div>
            <div className={styles.description}>
              Once it is authenticated using our Guaranteed, Fake Free
              Authentication Provider, DUNK will issue a NFT. You can check your
              Generated NFTs on "My NFTs" page!
            </div>
          </div>

          <div className={styles.nftItems}>
            <div className={styles.steps}>3</div>
            <div className={styles.heading}>Enjoy DUNK</div>
            <div className={styles.description}>
              You can now easily buy and sell sneaker NFTs. Enjoy Fake Free,
              Best Deals on DUNK Auction Services!
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Section3;
