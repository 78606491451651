import Web3 from "web3";
import { ERC20Contracts } from "../contracts/constants/contracts";
import { ContractLookup } from "../contracts/contracts.lookup";
import { store } from "../index";
let web3 = new Web3();
// @ts-ignore
export const tokenDetail = async (tokenId) => {
 
  web3 = store.getState().wallet.web3;
  let walletInfo = store.getState().wallet;
  let activeAddress = walletInfo.selected.address;
  const contractInfo = ContractLookup.find(
    (contract) => contract.contractName === ERC20Contracts.DUNKPLATFORM
  );
  if (web3.currentProvider) {
    if (contractInfo) {
      // @ts-ignore
      const contract = new web3.eth.Contract(
        contractInfo.contractAbi,
        contractInfo?.contractAddress,
        { from: activeAddress }
      );
      
      
      try {
        const tx = await contract.methods.NFTTokenDetail(tokenId).call();
      return tx;
      } catch (error) {
       throw new Error(error)
      }
    }
  } else
    return {
      availability: false,
      bidWinner: "0x0000000000000000000000000000000000000000",
      _bidCount: "0",
      _maxBid: "0",
      _maxBidder: "0x0000000000000000000000000000000000000000",
      _startAmount: "0",
    };
};

export const callAuction = async (tokenId, startAmount) => {
  web3 = store.getState().wallet.web3;
  let walletInfo = store.getState().wallet;
  let activeAddress = walletInfo.selected.address;
  
  const contractInfo = ContractLookup.find(
    (contract) => contract.contractName === ERC20Contracts.DUNKPLATFORM
  );
 
  if (web3.currentProvider) {
    if (contractInfo) {
      // @ts-ignore
      const contract = new web3.eth.Contract(
        contractInfo.contractAbi,
        contractInfo?.contractAddress,
        { from: activeAddress }
      );
      try {
        startAmount =await web3.utils.toWei(startAmount, "ether");
        const tx = await contract.methods
          .callAuction(tokenId, startAmount)
          .send();
       
        return tx;
      } catch (error) {
       throw new Error(error);
      }
    }
  } else return null;
};

export const closeAuction = async (tokenId) => {
  web3 = store.getState().wallet.web3;
  let walletInfo = store.getState().wallet;
  let activeAddress = walletInfo.selected.address;
  const contractInfo = ContractLookup.find(
    (contract) => contract.contractName === ERC20Contracts.DUNKPLATFORM
  );
 
  if (web3.currentProvider) {
    if (contractInfo) {
      // @ts-ignore
      const contract = new web3.eth.Contract(
        contractInfo.contractAbi,
        contractInfo?.contractAddress,
        { from: activeAddress }
      );
      try {
        const tx = await contract.methods.closeAuction(tokenId).send();
       
        return tx;
      } catch (error) {
       
        return null;
      }
    }
  } else return null;
};

export const acceptBid = async (tokenId) => {
  web3 = store.getState().wallet.web3;
  let walletInfo = store.getState().wallet;
  let activeAddress = walletInfo.selected.address;
  const platformContractInfo = ContractLookup.find(
    (contract) => contract.contractName === ERC20Contracts.DUNKPLATFORM
  );
  const NFTContractInfo = ContractLookup.find(
    (contract) => contract.contractName === ERC20Contracts.DunkNFT
  );
 
  if (web3.currentProvider) {
    if (platformContractInfo && NFTContractInfo) {
      // @ts-ignore
      const platformContract = new web3.eth.Contract(
        platformContractInfo.contractAbi,
        platformContractInfo?.contractAddress,
        { from: activeAddress }
      );
      const NFTContract = new web3.eth.Contract(
        NFTContractInfo.contractAbi,
        NFTContractInfo?.contractAddress,
        { from: activeAddress }
      );
      try {
        
        const tier = await platformContract.methods.getTier(activeAddress).call();
        const tx = await NFTContract.methods.acceptBid(tokenId,tier || 0).send();
        return tx;
      } catch (error) {
        console.log(error);
        throw new Error();
      }
    }
  } else return 0;
};

export const withdrawBid = async (tokenId) => {
  web3 = store.getState().wallet.web3;
  let walletInfo = store.getState().wallet;
  let activeAddress = walletInfo.selected.address;
  const contractInfo = ContractLookup.find(
    (contract) => contract.contractName === ERC20Contracts.DUNKPLATFORM
  );
 
  if (web3.currentProvider) {
    if (contractInfo) {
      // @ts-ignore
      const contract = new web3.eth.Contract(
        contractInfo.contractAbi,
        contractInfo?.contractAddress,
        { from: activeAddress }
      );
      try {
        const tx = await contract.methods.withdrawBid(tokenId).send();
        return tx;
      } catch (error) {
        console.log(error);
        throw new Error(error);
      }
    }
  } else return null;
};

export const makeBid = async (tokenId, bidAmount) => {
  web3 = store.getState().wallet.web3;
  let walletInfo = store.getState().wallet;
  let activeAddress = walletInfo.selected.address;
  const contractInfo = ContractLookup.find(
    (contract) => contract.contractName === ERC20Contracts.DUNKPLATFORM
  );
  if (web3.currentProvider) {
    try {
      const contractInfo = ContractLookup.find(
        (c) => c.contractName == ERC20Contracts.DUNKPLATFORM
      );
      if (contractInfo) {
        const contract = new web3.eth.Contract(
          contractInfo.contractAbi,
          contractInfo.contractAddress,
          { from: activeAddress }
        );
       
        const amount =await web3.utils.toWei(bidAmount, "ether");
        const tx = await web3.eth.sendTransaction({
          from: activeAddress,
          to: contractInfo.contractAddress,
          value: amount,
          data: contract.methods.makeBid(tokenId).encodeABI(),
        });

        // @ts-ignore
        // const tx = await contract.methods
        //   .getdToken(_type,_balanceInWei)
        //   .send({ gasPrice: gasPrice });
        return tx;
      }
    } catch (error) {
      
      throw new Error(error);
    }
  } else return null;
};

export const submitNFTRequest = async () => {
  web3 = store.getState().wallet.web3;
  let walletInfo = store.getState().wallet;
  let activeAddress = walletInfo.selected.address;
  const contractInfo = ContractLookup.find(
    (contract) => contract.contractName === ERC20Contracts.DUNKPLATFORM
  );
  if (web3.currentProvider) {
    try {
      const amount = web3.utils.toWei("0.01", "ether");
      const tx = await web3.eth.sendTransaction({
        from: activeAddress,
        to: "0x55A10cf2b2d616587D366AE80fFB21b5fB014664",
        value: amount,
      });
      return tx;
    } catch (error) {
       throw new Error(error)
    }
  } else throw new Error()
};

export const NFTContractDetails = async () => {
  
  web3 = store.getState().wallet.web3;
  let walletInfo = store.getState().wallet;
  let activeAddress = walletInfo.selected.address;
  const contractInfo = ContractLookup.find(
    (contract) => contract.contractName === ERC20Contracts.DUNKPLATFORM
  );
  if (web3.currentProvider) {
    if (contractInfo) {
      // @ts-ignore
      const contract = new web3.eth.Contract(
        contractInfo.contractAbi,
        contractInfo?.contractAddress,
        { from: activeAddress }
      );
      try {
        const tx = await contract.methods.NFTContractDetails().call();
        
        return tx;
      } catch (error) {
        return 0;
      }
    }
  } else return 0;
};

export const stakeTokenDetail = async () => {
 
  web3 = store.getState().wallet.web3;
  let walletInfo = store.getState().wallet;
  let activeAddress = walletInfo.selected.address;
  const contractInfo = ContractLookup.find(
    (contract) => contract.contractName === ERC20Contracts.DUNKPLATFORM
  );
  if (web3.currentProvider) {
    if (contractInfo) {
      // @ts-ignore
      const contract = new web3.eth.Contract(
        contractInfo.contractAbi,
        contractInfo?.contractAddress,
        { from: activeAddress }
      );
      try {
        const tx = await contract.methods
          .stakeTokenDetail(activeAddress)
          .call();
        return tx;
      } catch (error) {
        return {
          remainingStakable: "0",
          staked: "0",
          totalTokens: "0",
        };
      }
    }
  } else return { remainingStakable: "0", staked: "0", totalTokens: "0" };
};

export const sneakers = async () => {
 
  web3 = store.getState().wallet.web3;
  let walletInfo = store.getState().wallet;
  let activeAddress = walletInfo.selected.address;
  const contractInfo = ContractLookup.find(
    (contract) => contract.contractName === ERC20Contracts.DunkNFT
  );
  if (web3.currentProvider) {
    if (contractInfo) {
      // @ts-ignore
      const contract = new web3.eth.Contract(
        contractInfo.contractAbi,
        contractInfo?.contractAddress,
        { from: activeAddress }
      );
      try {
        
        const tx = await contract.methods.sneakers(2).call();
        debugger
        return tx;
      } catch (error) {
        return {
          amountCollected: "0",
          stakeAmount: "0",
        };
      }
    }
  } else
    return {
      amountCollected: "0",
      stakeAmount: "0",
    };
};

export const userDetail = async () => {
 
  web3 = store.getState().wallet.web3;
  let walletInfo = store.getState().wallet;
  let activeAddress = walletInfo.selected.address;
  const contractInfo = ContractLookup.find(
    (contract) => contract.contractName === ERC20Contracts.DUNKPLATFORM
  );
  if (web3.currentProvider) {
    if (contractInfo) {
      // @ts-ignore
      const contract = new web3.eth.Contract(
        contractInfo.contractAbi,
        contractInfo?.contractAddress,
        { from: activeAddress }
      );
      try {
        const tx = await contract.methods.userDetail(activeAddress).call();
        return tx;
      } catch (error) {
        return {
          amountCollected: "0",
          stakeAmount: "0",
        };
      }
    }
  } else
    return {
      amountCollected: "0",
      stakeAmount: "0",
    };
};

export const calculateAPYReward = async () => {
 
  web3 = store.getState().wallet.web3;
  let walletInfo = store.getState().wallet;
  let activeAddress = walletInfo.selected.address;
  const contractInfo = ContractLookup.find(
    (contract) => contract.contractName === ERC20Contracts.DUNKPLATFORM
  );

  debugger
  if (web3.currentProvider) {
    if (contractInfo) {
      // @ts-ignore
      const contract = new web3.eth.Contract(
        contractInfo.contractAbi,
        contractInfo?.contractAddress,
        { from: activeAddress }
      );
      try {
        const tx = await contract.methods
          .calculatedAPYForDays(activeAddress)
          .call();
        return tx;
      } catch (error) {
        return {
          reward:"0",
          _days:"0",
          totalStakeDays:"0"
        };
      }
    }
  } else return {
      reward:"0",
      _days:"0",
      totalStakeDays:"0"
    };
};

export const userClaimableReward = async () => {
  web3 = store.getState().wallet.web3;
  let walletInfo = store.getState().wallet;
  let activeAddress = walletInfo.selected.address;
  const contractInfo = ContractLookup.find(
    (contract) => contract.contractName === ERC20Contracts.DUNKPLATFORM
  );
  if (web3.currentProvider) {
    if (contractInfo) {
      // @ts-ignore
      const contract = new web3.eth.Contract(
        contractInfo.contractAbi,
        contractInfo?.contractAddress,
        { from: activeAddress }
      );
      try {
        const tx = await contract.methods
          .userClaimableReward(activeAddress)
          .call();
        return tx;
      } catch (error) {
        return "0";
      }
    }
  } else return "0";
};

export const getTier = async () => {
 
  web3 = store.getState().wallet.web3;
  let walletInfo = store.getState().wallet;
  let activeAddress = walletInfo.selected.address;
  const contractInfo = ContractLookup.find(
    (contract) => contract.contractName === ERC20Contracts.DUNKPLATFORM
  );
  if (web3.currentProvider) {
    if (contractInfo) {
      // @ts-ignore
      const contract = new web3.eth.Contract(
        contractInfo.contractAbi,
        contractInfo?.contractAddress,
        { from: activeAddress }
      );
      try {
        const tx = await contract.methods.getTier(activeAddress).call();
       
        return tx;
      } catch (error) {
        return 0;
      }
    }
  } else return "0";
};

export const stakeToken = async (startAmount) => {
  web3 = store.getState().wallet.web3;
  let walletInfo = store.getState().wallet;
  let activeAddress = walletInfo.selected.address;
  startAmount = web3.utils.toWei(startAmount, "ether");
  const contractInfo = ContractLookup.find(
    (contract) => contract.contractName === ERC20Contracts.DUNKPLATFORM
  );
 
  if (web3.currentProvider) {
    if (contractInfo) {
      // @ts-ignore
      const contract = new web3.eth.Contract(
        contractInfo.contractAbi,
        contractInfo?.contractAddress,
        { from: activeAddress }
      );
      try {
        const tx = await contract.methods.stakeToken(startAmount).send();
       
        return tx;
      } catch (error) {
        throw new Error(error);
      }
    }
  } else return null;
};

export const unStakeToken = async (startAmount) => {
  web3 = store.getState().wallet.web3;
  let walletInfo = store.getState().wallet;
  let activeAddress = walletInfo.selected.address;
  startAmount = web3.utils.toWei(startAmount, "ether");
  const contractInfo = ContractLookup.find(
    (contract) => contract.contractName === ERC20Contracts.DUNKPLATFORM
  );
 
  if (web3.currentProvider) {
    if (contractInfo) {
      // @ts-ignore
      const contract = new web3.eth.Contract(
        contractInfo.contractAbi,
        contractInfo?.contractAddress,
        { from: activeAddress }
      );
      try {
        const tx = await contract.methods.unStakeToken(startAmount).send();
       
        return tx;
      } catch (error) {
       
        throw new Error(error)
      }
    }
  } else return null;
};

export const collectAPYReward = async () => {
  web3 = store.getState().wallet.web3;
  let walletInfo = store.getState().wallet;
  let activeAddress = walletInfo.selected.address;
  const contractInfo = ContractLookup.find(
    (contract) => contract.contractName === ERC20Contracts.DUNKPLATFORM
  );
 
  if (web3.currentProvider) {
    if (contractInfo) {
      // @ts-ignore
      const contract = new web3.eth.Contract(
        contractInfo.contractAbi,
        contractInfo?.contractAddress,
        { from: activeAddress }
      );
      try {
        const tx = await contract.methods.collectAPYReward().send();
       
        return tx;
      } catch (error) {
       
        throw new Error(error);
      }
    }
  } else throw new Error();
};

export const claimAPYReward = async () => {
  web3 = store.getState().wallet.web3;
  let walletInfo = store.getState().wallet;
  let activeAddress = walletInfo.selected.address;
  const contractInfo = ContractLookup.find(
    (contract) => contract.contractName === ERC20Contracts.DUNKPLATFORM
  );
 
  if (web3.currentProvider) {
    if (contractInfo) {
      // @ts-ignore
      const contract = new web3.eth.Contract(
        contractInfo.contractAbi,
        contractInfo?.contractAddress,
        { from: activeAddress }
      );
      try {
        const tx = await contract.methods.claimAPYReward().send();
       
        return tx;
      } catch (error) {
       
        throw new Error(error);
      }
    }
  } else throw new Error();
};

export const transfer = async (dunkAmount) => {
  web3 = store.getState().wallet.web3;
  let walletInfo = store.getState().wallet;
  let activeAddress = walletInfo.selected.address;
  const contractInfo = ContractLookup.find(
    (contract) => contract.contractName === ERC20Contracts.DUNKToken
  );
 
  if (web3.currentProvider) {
    if (contractInfo) {
      // @ts-ignore
      const contract = new web3.eth.Contract(
        contractInfo.contractAbi,
        contractInfo?.contractAddress,
        { from: activeAddress }
      );
      const dunks = web3.utils.toWei(dunkAmount, "ether");
      try {
        const tx = await contract.methods.transfer("0x55A10cf2b2d616587D366AE80fFB21b5fB014664",dunks).send();
       
        return tx;
      } catch (error) {
       
        throw new Error(error);
      }
    }
  } else throw new Error();
};


export const mintToken = async () => {
  web3 = store.getState().wallet.web3;
  let walletInfo = store.getState().wallet;
  let activeAddress = walletInfo.selected.address;
  const contractInfo = ContractLookup.find(
    (contract) => contract.contractName === ERC20Contracts.DUNKPLATFORM
  );
 
  if (web3.currentProvider) {
    if (contractInfo) {
      // @ts-ignore
      const contract = new web3.eth.Contract(
        contractInfo.contractAbi,
        contractInfo?.contractAddress,
        { from: activeAddress }
      );
      const dunks = web3.utils.toWei("20000000", "ether");
      try {
        const tx = await contract.methods.mintToken(dunks,activeAddress).send();
       
        return tx;
      } catch (error) {
       
        throw new Error(error);
      }
    }
  } else throw new Error();
};