import React, { useState } from "react";
import styles from "./SignIn.module.scss";
import { Link ,useHistory} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userLogIn } from "../../../store/actions/authActions";
import { useFormik } from "formik";
import Loader from "react-loader-spinner";
import * as Yup from "yup";
import { web3Sources } from "../../../constants";

export default function SignIn({ connectToWallet, address, isConnected }) {
  const history = useHistory();
  const initialState = {
    password: "",
  };
  const {isLoggingIn,logInErrorMessage} = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [showPassword,setShowPassword]=useState(false)
  const handleSubmit = async (password) => {
    
    const res = await dispatch(userLogIn(address, password));
    console.log(res);
  if(res?.success){
    history.push("/")
  }
  };
  const formik = useFormik({
    initialValues: initialState,
    validationSchema: Yup.object({
      password: Yup.string()
        .required("Required"),
    }),
    onSubmit: (values, onSubmitProps) => {
      handleSubmit(values.password);
      console.log(values);
    },
  });
  return (
    <div className={styles.wrapper}>
      <div className={styles.signUpDiv}>
        <div className={styles.heading}>Sign In</div>
        <form onSubmit={formik.handleSubmit}>
          {}
          <div
            className={
              isConnected ? styles.connectedWallet : styles.connectWallet
            }
            onClick={() => connectToWallet(web3Sources.Metamask)}
          >
            {isConnected ? (
              <>
                Wallet Connected (
                {address.substring(0, 4) +
                  "....." +
                  address.substring(address.length - 4, address.length)}
                )
              </>
            ) : (
              "Connect Your Wallet"
            )}
          </div>
          <div className={styles.inputDiv}>
            <p>Password</p>
            <div className={styles.passwordField}>
              <input
                type={showPassword ? "text":"password"}
                placeholder="Password"
                name="password"
                // onChange={handleInputChange}
                {...formik.getFieldProps("password")}
                className={`${styles.inputField} ${styles.passwordIcon}`}
              />
              <img
              src={showPassword ? "/assets/view.svg":"/assets/hide_password.svg"}
              className={styles.passwordImg}
              onClick={()=>setShowPassword((prev)=>!prev)}
              />
            </div>
            {formik.touched.password && formik.errors.password ? (
              <div className={styles.errorDiv}>{formik.errors.password}</div>
            ) : null}
          </div>
          <div className={styles.linkDiv}>
            Don`t have an account?
          <Link className={styles.link} to="/auth/signUp">Sign Up!</Link>
          </div>
          <div className={styles.errorMessage}>
                {logInErrorMessage}
              </div>
          <button type="submit" className={styles.button}>
            {isLoggingIn ? (
              <Loader type="Bars" color="#ffffff" height={30} width={40} />
            ) : (
              "Sign In"
            )}
          </button>
        </form>
      </div>
    </div>
  );
}
