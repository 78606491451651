import React from "react";
import styles from "./Section3.module.scss";
function Section3() {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.colorDiv}>
          <div className={styles.howToUse}>
            <h4>Newsletter</h4>
            <pre className={styles.headingDiv}>
              Subscribe
              <div className={styles.dunk}> DUNK</div>
            </pre>
            <div className={styles.description}>For our future updates</div>
            <div className={styles.passwordField}>
            <input
              type="text"
              placeholder="Your Email"
              name="repeatPassword"
              className={`${styles.blueField}`}
            />
            <div className={styles.imgDiv}>
            <img
              src="/assets/Path 345.svg"
              className={styles.passwordImg}
            />
            </div>
          </div>
         
          </div>
          <div className={styles.knowMore}>
            <h4>Want to know more about DUNK?</h4>
            <img
              src="/assets/Path 429.svg"
            />
           </div>
        </div>
      </div>
    </div>
  );
}
export default Section3;
