import React from "react";
import styles from "./Section1.module.scss";
function Section1({heading,features,children}) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <h1>{heading}</h1>
        <div className={styles.features}>{features}</div>
        {children}
      </div>
    </div>
  );
}
export default Section1;
