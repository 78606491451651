// import React from "react";
// import styles from "./Card.module.scss";
// const Card = (props) => {
//   return (
//     <div className={styles.wrapper}>
//       <div
//         className={styles.container}
//         style={{
//           border: "2px solid `${props.border}`",
//         }}
//       >
//         <div
//           className={`${styles.soldLabel} ${props.soldOut ? "" : styles.hide} ${
//             styles.topLeft
//           }`}
//         >
//           SOLD OUT
//         </div>
//         <div
//           className={`${styles.redeem} ${props.redeem ? "" : styles.hide} ${
//             styles.topLeft
//           }`}
//         >
//           Redeemable
//         </div>
//         <div className={`${styles.dunk} ${styles.topLeft}`}></div>
//         <div className={styles.imgDiv}>
//           <img className={styles.showImage} src={props.img} alt="Show Image" />
//           <h3 className={`${props.redeem ? "" : styles.hide}`}>$REVERSE</h3>
//         </div>
//         <div className={styles.details}>
//           <div className={styles.showDetails}>
//             <div className={styles.name}>{props.name}</div>
//             <div className={styles.price}>USD ${props.price} </div>
//             <div className={styles.sold}>{props.sold} sold out</div>
//           </div>
//           <div className={styles.userDetails}>
//             <img className={styles.userImg} src="/assets/user.svg" alt="user image" />
//             <div className={styles.userName}>{props.userName}</div>
//           </div>
//         </div>
//       </div>
//     </div>

//   );
// };

// export default Card;

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { clipping } from "../../utils/helper/comman";
import { toFixedNoRounding } from "../__common/FixedNoRounding";

import heart from "../../assets/heart-icon.svg";
import unlike from "../../assets/unlike-icon.svg";
import styles from "./Card.module.scss";
import { getETHPrice } from "../../utils/helper/ethPrice";
const Card = ({ item, myNFTs, handleLike }) => {
  const history = useHistory();
  const [oneETH, setOneETH] = useState(0);
  const {
    selected: { address, isConnected },
  } = useSelector((state) => state.wallet);
  useEffect(() => {
    getETHPrice().then((res) => {
      console.log(res.data.ethereum.usd);
      setOneETH(res.data.ethereum.usd);
    });
  });
  const handleLikeAction = async (e, id) => {
    
    // await handleNftLike(id,address)
    // window.addEventListener()
    e.stopPropagation();
    await handleLike(id, address);
    
    // const res = await instance.post(`requests/like/${id}`, {
    //   address: address,
    // });
    
    // setData({ ...res.data.data });
  };
  return (
    <div
      className={`${styles.card} ${
        (item?.tier === 0 && styles.tier3Border) ||
        (item?.tier === 1 && styles.tier2Border) ||
        (item?.tier === 2 && styles.tier1Border)
      }`}
      onClick={() =>
        history.push(
          `${
            myNFTs
              ? myNFTs
              : item?.lottery
              ? "/create-loan/"
              : "/product-details/"
          }${item?._id}`
        )
      }
    >
      <div className={styles.flexViews}>
        <div className={styles.cardLogo}>
          <img alt="img" src="/assets/cardLogo.svg" />

          <div className={styles.views}>
            {address && (
              <>
                <img
                  onClick={(e) => handleLikeAction(e, item._id)}
                  src={
                    item?.likes?.find((item) => item === address)
                      ? heart
                      : unlike
                  }
                  alt="heart icon"
                  style={{
                    height: "25px",
                    width: "25px",
                    cursor: "pointer",
                    marginRight: "5px",
                    zIndex: "100000",
                    objectFit: "contain",
                  }}
                />
              </>
            )}
            {item?.likesCount}
          </div>
        </div>
        {/* <div className={styles.views}>3.5k</div> */}
        <div className={`${styles.tier} ${item?.tier === 0 && styles.tier3 || item?.tier === 1 && styles.tier2 || item?.tier === 2 && styles.tier1}`}>
          TIER {item?.tier === 0 && "3" || item?.tier === 1 && "2" || item?.tier === 2 && "1"}
        </div>
      </div>
      <div className={styles.imgDiv}>
        {/* <div className={styles.flexViews}>
          <div className={styles.cardLogo}>
            <img alt="img" src="/assets/cardLogo.svg" />
            <div className={styles.views}>{item?.likesCount}</div>
          </div>
        </div> */}
        <div className={styles.imgWrapper}>
          <img
            alt="img"
            src={`${process.env.REACT_APP_BASE_URL}/requests/file/${item?.image[0].folderName}/${item?.image[0].name}`}
          />
        </div>
      </div>
      <div className={styles.flexROw}>
        <div className={styles.title}>{clipping(item?.modelName)}</div>
        <div className={styles.userDiv}>
          {/* <div className={styles.profileImg}>
          <img
            className={styles.userImg}
            alt="img"
            src="/assets/user.svg"
          />
        </div> */}
          {/* <div className={styles.userName}>Kevin Woodkin</div> */}
          {/* <div className={styles.owner}>Owner</div> */}
          {!item?.lottery && (
            <div className={styles.userName}>
              ({item?.ownerAddress.substring(0, 7) + ".."})
            </div>
          )}
        </div>
      </div>
      {item?.lottery ? (
        <>
          <div className={styles.flexROw}>
            <div className={styles.startingFrom}>Retail Price</div>
          </div>

          <div className={styles.priceDanger}>
            {/* {item?.price} ETH  */} USD ($
            {item?.retailPrice}){" "}
          </div>
        </>
      ) : (
        <>
          <div className={styles.flexROw}>
            <div className={styles.startingFrom}>Starting From</div>
          </div>

          <div className={styles.priceDanger}>
            ETH (Ξ {item?.price})
            {/* USD ($
            {(item?.price && toFixedNoRounding(item?.price * oneETH, 2)) ||
              0}){" "} */}
          </div>
        </>
      )}
    </div>
    // <div className={styles.wrapper}>
    //   <div
    //     className={styles.container}
    //     style={{
    //       border: "2px solid `${props.border}`",
    //     }}
    //   >
    //     <div
    //       className={`${styles.soldLabel} ${props.soldOut ? "" : styles.hide} ${
    //         styles.topLeft
    //       }`}
    //     >
    //       SOLD OUT
    //     </div>
    //     <div
    //       className={`${styles.redeem} ${props.redeem ? "" : styles.hide} ${
    //         styles.topLeft
    //       }`}
    //     >
    //       Redeemable
    //     </div>
    //     <div className={`${styles.dunk}` `${styles.topLeft}`}></div>
    //     <div className={styles.imgDiv}>
    //       <img className={styles.showImage} src={props.img} alt="Show Image" />
    //       <h3 className={`${props.redeem ? "" : styles.hide}`}>$REVERSE</h3>
    //     </div>
    //     <div className={styles.details}>
    //       <div className={styles.showDetails}>
    //         <div className={styles.name}>{props.name}</div>
    //         <div className={styles.price}>USD ${props.price} </div>
    //         <div className={styles.sold}>{props.sold} sold out</div>
    //       </div>
    //       <div className={styles.userDetails}>
    //         <img className={styles.userImg} src="/assets/user.svg" alt="user image" />
    //         <div className={styles.userName}>{props.userName}</div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default Card;
