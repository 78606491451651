import React, { useState, useEffect } from "react";
import { getETHPrice } from "../../../utils/helper/ethPrice";
import instance from "../../../utils/helper/http.helper";
import styles from "./RelatedNFTs.module.scss";
import Card from "../../DunkAuction/Card";
export default function RelatedNFTs({ history, heading, boldHeading, para }) {
  const [oneETH, setOneETH] = useState(0);
  const [data, setData] = useState([]);
  const getRelated = async () => {
    return await instance.get("/requests?status=approved&auction=on");
  };
  const pushAction = (item) => {
    
    history.push(`product-details/${item._id}`);
  };
  const handleAuctionLike = async (id,address) => {
    
    const res = await instance.post(`requests/like/${id}`, {
      address: address,
    });
    const index = data.findIndex(obj => obj._id === id);
    let newArray=[...data]
    newArray[index]=res.data.data
    setData([...newArray]);
  };
  useEffect(() => {
    getRelated().then((res2) => {
      console.log(res2, "GET RESULT");
      setData(res2.data.data);
    });
    getETHPrice().then((res) => {
      console.log(res.data.ethereum.usd);
      setOneETH(res.data.ethereum.usd);
    });
  }, []);
  return (
    <>
      <div className={styles.headingDiv}>
        <h3>
          {heading} <b>{boldHeading}</b>
        </h3>
        <p>{para}</p>
      </div>

      <div className={styles.flexCard}>
        <div className={styles.cardDiv}>
          {data?.length > 0 ? (
            data.map((item) => (
              <Card item={item || []} oneETH={oneETH} handleLike={handleAuctionLike}/>
              
            ))
          ) : (
            <div style={{ textAlign: "center", width: "100%" }}>
              No data found
            </div>
          )}
          
        </div>
      </div>
    </>
  );
}
