import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { AuctionTypes } from "../../constants";
import instance from "../../utils/helper/http.helper";
import SimpleSlider from "../__common/SimpleSlider/SimpleSlider";
import styles from "./DunkLoan.module.scss";
import Card from "../DunkAuction/Card";

export default function DunkLoan() {
  const [auctionType, setAuctionType] = useState("");
  const {
    selected: { address, isConnected },
  } = useSelector((state) => state.wallet);
  const [data, setData] = useState([]);
  const handleAuctionLike = async (id, address) => {
    const res = await instance.post(`requests/like/${id}`, {
      address: address,
    });
    const index = data.findIndex((obj) => obj._id === id);
    let newArray = [...data];
    newArray[index] = res.data.data;
    setData([...newArray]);
  };
  const getAll = async () => {
    return await instance.get("/requests?status=approved");
  };
  const getNFTs = async () => {
    const others = await getAll();
    console.log(others);
    setData(others.data.data);
  };
  useEffect(() => {
    getNFTs();
  }, [auctionType]);
  const history = useHistory();

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.flexCard}>
          {data &&
          data.length > 0 &&
          data.filter((item) => item.lottery === true).length > 0 ? (
            data
              .filter((item) => item.lottery === true)
              .map((item, i) => (
                <Card item={item || []} handleLike={handleAuctionLike} />
                // <div
                //   className={styles.card}
                //   onClick={() => history.push(`/create-loan/${item._id}`)}
                //   key={i}
                // >
                //   <div className={styles.imgDiv}>
                //     <img
                //       alt="img"
                //       src={`${process.env.REACT_APP_BASE_URL}/requests/file/${item.image[0].folderName}/${item.image[0].name}`}
                //     />
                //     {/* <div className={styles.redeemable}>Redeemable</div>
                //     <div className={styles.reverse}>$REVERSE</div> */}
                //   </div>
                //   <div className={styles.flexROw}>
                //     <div className={styles.title}>{item.modelName}</div>
                //     {/* <div className={styles.userDiv}>
                //       <div className={styles.owner}>Owner</div>
                //       <div className={styles.userName}>
                //         ({item.ownerAddress.substring(0, 7) + ".."})
                //       </div>
                //     </div> */}
                //   </div>
                //   {/* <div className={styles.priceDanger}>USD $229.00</div>
                //   <div className={styles.soldOut}>4,0420 sold out</div> */}
                // </div>
              ))
          ) : (
            <div style={{ textAlign: "center", width: "100%" }}>
              No data found
            </div>
          )}
          {/* <div className={styles.card} onClick={() => history.push("/authentication")}>
            <div className={styles.imgDiv}>
              <img alt="img" src="/assets/Mask3.png" />
              <div className={styles.redeemable}>Redeemable</div>
              <div className={styles.reverse}>$REVERSE</div>
            </div>
            <div className={styles.flexROw}>
              <div className={styles.title}>2021 ALL-STAR GAME</div>
              <div className={styles.userDiv}>
                <div className={styles.profileImg}>
                  <img alt="img" src="/assets/user.svg" />
                </div>
                <div className={styles.userName}>Kevin Woodkin</div>
              </div>
            </div>
            <div className={styles.priceDanger}>USD $229.00</div>
            <div className={styles.soldOut}>4,0420 sold out</div>
          </div>
         */}
        </div>
      </div>
    </div>
  );
}
