import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import styles from "./SignUp.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { userSignUp } from "../../../store/actions/authActions";
import { web3Sources } from "../../../constants";
import Loader from "react-loader-spinner";

import instance from "../../../utils/helper/http.helper";

export default function SignUp({
  connectToWallet,
  address,
  isConnected,
  errorMessage,
}) {
  const history = useHistory();
  const initialState = {
    password: "",
    repeatPassword: "",
  };
  const { isSigningUp } = useSelector((state) => state.auth);
const [showPassword,setShowPassword]=useState(false)
const [showRepPassword,setShowRepPassword]=useState(false)
  const dispatch = useDispatch();
  const handleSubmit = async (password, onSubmitProps) => {
    
    const res = await dispatch(userSignUp(address, password));
    console.log(res);
    if (res?.success) {
      history.push("/");
    }
  };
  const formik = useFormik({
    initialValues: initialState,
    validationSchema: Yup.object({
      password: Yup.string()
        .required("Required")
        .min(8, "Minimum 8 characters are required.")
        .matches(
          /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&])([a-zA-Z0-9@$!%*?&]{8,})$/,
          "Password should contain one capital letter,one small letter,one special character and one number."
        ),
      repeatPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Password must match")
        .required("Required"),
    }),
    onSubmit: (values, onSubmitProps) => {
      handleSubmit(values.password, onSubmitProps);
      console.log(values);
    },
  });
  return (
    <div className={styles.wrapper}>
      {!isConnected ? (
        <div className={styles.connectWalletDiv}>
          <div className={styles.heading}>Connect Wallet</div>
          <div className={styles.detail}>
            Connect Your MetaMask Wallet To Proceed Sign Up
          </div>
          <div
            className={styles.button}
            onClick={() => connectToWallet(web3Sources.Metamask)}
          >
            Connect Your Wallet
          </div>
          <div className={styles.linkDiv}>
            Already have an account?
            <Link className={styles.link} to="/auth/signIn">
              Sign In!
            </Link>
          </div>
        </div>
      ) : (
        <div className={styles.signUpDiv}>
          <div className={styles.heading}>Sign UP</div>
          <form onSubmit={formik.handleSubmit}>
            <div className={styles.connectedWallet}>
              Wallet Connected (
              {address.substring(0, 4) +
                "....." +
                address.substring(address.length - 4, address.length)}
              )
            </div>
            <div className={styles.inputDiv}>
              <p>Password</p>
              <div className={styles.passwordField}>
                <input
                  type={showPassword ? "text":"password"}
                  placeholder="Password"
                  name="password"
                  // onChange={handleInputChange}
                  {...formik.getFieldProps("password")}
                  className={`${styles.inputField} ${styles.passwordIcon}`}
                />
                <img
                  src={showPassword ? "/assets/view.svg":"/assets/hide_password.svg"}
                  className={styles.passwordImg}
                  onClick={()=>setShowPassword((prev)=>!prev)}
                />
              </div>
              {formik.touched.password && formik.errors.password ? (
                <div className={styles.errorDiv}>{formik.errors.password}</div>
              ) : null}
              <p>Confirm Password</p>
              <div className={styles.passwordField}>
                <input
                 type={showRepPassword ? "text":"password"}
                  placeholder="Repeat Password"
                  // onChange={handleInputChange}
                  name="repeatPassword"
                  {...formik.getFieldProps("repeatPassword")}
                  className={`${styles.inputField} ${styles.passwordIcon}`}
                />
                <img
                  src={showRepPassword ? "/assets/view.svg":"/assets/hide_password.svg"}
                  className={styles.passwordImg}
                  onClick={()=>setShowRepPassword((prev)=>!prev)}
                />
              </div>
              {formik.touched.repeatPassword && formik.errors.repeatPassword ? (
                <div className={styles.errorDiv}>
                  {formik.errors.repeatPassword}
                </div>
              ) : null}{" "}
            </div>
            <div className={styles.errorMessage}>{errorMessage}</div>
            <div className={styles.linkDiv}>
              Already have an account?
              <Link className={styles.link} to="/auth/signIn">
                Sign In!
              </Link>
            </div>
            <button type="submit" className={styles.button}>
              {isSigningUp ? (
                <Loader type="Bars" color="#ffffff" height={30} width={40} />
              ) : (
                "Sign Up"
              )}
            </button>
          </form>
        </div>
      )}
    </div>
  );
}
