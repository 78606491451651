import React from 'react'
import Modal from "react-modal";
import styles from "./DunkAmountModel.module.scss";
export default function DunkAmountModel({isOpen,handleConnectOpen,placeholder,handleChange,currency,handleClick}) {
    return (
        <Modal
        isOpen={isOpen}
        onRequestClose={handleConnectOpen}
        contentLabel="Dunk Modal"
        className={styles.modal}
        overlayClassName={styles.modalOverlay}
      >
        {/* Modal Content Here */}
        <div className={styles.modalContent}>
          <div className={styles.header}>
            <div className={styles.cancel}>
              <img
                onClick={handleConnectOpen}
                src="/assets/alert/Cross.svg"
                alt="cancel"
              />
            </div>
          </div>
          <div className={styles.modelBody}>
            <div className={styles.inputDiv}>
              <input
                type="number"
                placeholder={placeholder}
                onChange={handleChange}
              />
              <div className={styles.currency}>{currency}</div>
            </div>
            <button onClick={handleClick}>Submit</button>
          </div>
        </div>
      </Modal>
    )
}
