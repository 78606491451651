import React, { useEffect, useState } from "react";
import styles from "./CreateDunkLoan.module.scss";
import { useParams } from "react-router";
import { useAlert } from "react-alert";
import Modal from "react-modal";
import Web3 from "web3";
import Loader from "react-loader-spinner";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import arrowUp from "../../assets/arrow-down.svg";
import instance from "../../utils/helper/http.helper";
import {
  makeBid,
  tokenDetail,
  transfer,
  withdrawBid,
} from "../../services/NFTCreation.service";
import axios from "axios";
import { toFixedNoRounding } from "../__common/FixedNoRounding";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

export default function CreateDunkLoan() {
  const alert = useAlert();
  const {
    selected: { address, isConnected },
  } = useSelector((state) => state.wallet);
  const clipping = (string) => {
    if (string.length > 20) {
      const new_string = string.substring(0, 20) + ".....";
      return new_string;
    }
    return string;
  };

  const transferAsync = async () => {
    
    try {
      const res =await transfer(dunkAmount);
      if (res?.status) {
        await instance.patch(`/requests/participate/${id}`, {
          address: address,
          amount: dunkAmount,
        });
        alert.show("You have successfully traded Tickets with DUNK", {
          type: "success",
        });
        setDunkAmount("")
        setIsBuyin(false);
      }
      setIsBuyin(false);
      setDunkAmount("")
    } catch (err) {
      setIsBuyin(false);
      setDunkAmount("")
      console.log(err);
      alert.show("Something Went Wrong.", {
        type: "error",
      });
    }
    // requests/participate/:id
  };
  const getImages = (ar) => {
    let temp = [];
    for (let i = 0; i < ar.length; i++) {
      temp.push({
        original: `${process.env.REACT_APP_BASE_URL}/requests/file/${ar[i].folderName}/${ar[i].name}`,
        thumbnail: `${process.env.REACT_APP_BASE_URL}/requests/file/${ar[i].folderName}/${ar[i].name}`,
      });
    }
    return temp;
  };
  const { id } = useParams();
  const [activeIndex, setActiveIndex] = useState(0);
  const [dunkAmount, setDunkAmount] = useState("");
  const [isBuyin, setIsBuyin] = useState(false);

  const [data, setData] = useState({});
  const getApproved = async () => {
    const res2 = await instance.get(`/requests/${id}`);
    return res2;
  };
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    getApproved().then((res2) => {
      setData({ ...res2.data.data });
    });
  }, [id]);
  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <div className={styles.flexRow}>
            <div className={styles.productImgDiv}>
              <div></div>
              
              {data?.image ? (
                <>
                  <ImageGallery
                    items={getImages(data.image)}
                    originalHeight="200px"
                    showNav={false}
                    showFullscreenButton={false}
                    showPlayButton={false}
                  />
                </>
              ) : null}
              <div></div>
            </div>
            <div className={styles.productDetailsDiv}>
              <div className={styles.modalName}>
                {data?.modelName && clipping(data?.modelName)}
              </div>

              <>
                <div className={styles.auctionDetails}>
                  <div className={styles.key} style={{ marginTop: "50px" }}>
                    Retail Price
                  </div>
                  <div className={styles.value}>${data?.retailPrice}</div>
                </div>
                <div className={styles.auctionDetails}>
                  <p className={styles.para}>
                    Purchase more Tickets with DUNK Tokens to increase your
                    chance of winning this NFT!
                  </p>
                </div>
                {/* <div className={styles.auctionDetails}>
                  <div className={styles.key}>Interest Rate per month (5%)</div>
                  <div className={styles.value}>$25</div>
                </div>
                <div className={styles.auctionDetails}>
                  <div className={styles.key}>Gas Fee</div>
                  <div className={styles.value}>$10.00</div>
                </div>{" "} */}
              </>
              <div className={styles.row}>
                <div className={styles.btnDiv}>
                  <p className={styles.excahange}>
                    Exchange Tickets with DUNK Tokens
                  </p>
                  {/* <h1>2 DUNK</h1> */}
                  <input
                    type="text"
                    value={dunkAmount}
                    placeholder="Enter Dunk Amount"
                    onChange={(e) => setDunkAmount(e.target.value)}
                  />
                  {address && (
                    <button
                      onClick={transferAsync}
                      className={`${styles.actionButton} ${styles.activeButton}`}
                      disabled={isBuyin ? true : false}
                    >
                      {isBuyin ? (
                        <Loader
                          type="Bars"
                          color="#ffffff"
                          height={30}
                          width={40}
                        />
                      ) : (
                        "Buy Ticket >"
                      )}

                      {/* <img alt="img" src="/assets/arrow-left-on hover.svg" />{" "} */}
                    </button>
                  )}
                </div>
                {/* <button
                    className={`${styles.actionButton}`}
                    onClick={withdrawBidAction}
                  >
                    {isWithdrawing ? (
                      <Loader
                        type="Bars"
                        color="black"
                        height={30}
                        width={40}
                      />
                    ) : (
                      <>
                        Withdraw Bid{" "}
                        <img alt="img" src="/assets/arrow-left-2.2.svg" />{" "}
                      </>
                    )}
                  </button> */}
              </div>
            </div>
          </div>

          <div className={styles.flexRow}>
            {/* <div className={styles.graphDiv}>
              {/* <img alt="img" src="/assets/Graph.svg" />
              <div>Current Price = 0.000000000000000001 ({"<"} $0.01)</div>
              <div>
                <Line data={[2,5,10,34,64,90,149,135]} />
              </div>
              <div>
                <p>Last Trade Price</p>

                <div>
                  $ 50,150.00
                  <img src="" alt="" />
                  <span>+2.5%</span>
                </div>
              </div>
            </div> */}
            <div className={styles.detailsWrapper}>
              <div className={styles.details}>
                <div className={styles.heading}>
                  Details
                  <img src={arrowUp} alt="arrowUp" />
                </div>
                <div className={styles.userInfo}>
                  {/* <img src={user} alt="" /> */}
                  <span className={styles.createdBy}>Created by</span>
                  <span className={styles.userName}>
                    {data?.ownerAddress?.substring(0, 6)}
                  </span>
                </div>
                <p>
                  Remarks: {data?.remarks}
                  {/* Hashmask names can change at any time. Immediately
                  before purchasing a Hashmask, enter the Hashmask’s token ID
                  into the tokenNaneBy Index function on site like Etherscan to
                  verify that the blockchain indicates that the Hashmask you’re
                  purchasing has the name you expect. */}
                </p>
              </div>
              <div className={styles.chainInfo}>
                <div className={styles.heading}>
                  Chain Info
                  <img src={arrowUp} alt="arrowUp" />
                </div>
                <div className={styles.contact}>
                  Contract Address
                  <pre>0x526cAb1168Fb35b635c61Cb32cac51b872ccf8b8</pre>
                </div>
                {/* <div className={styles.tokenId}>
                  Token ID
                  <pre>2520</pre>
                </div> */}
                <div className={styles.blockChain}>
                  Blockchain
                  <pre>Ethereum</pre>
                </div>
              </div>
            </div>
            <div className={styles.sneakerDetails}>
              {/* <div className={styles.key}>Model Name</div>
              <div className={styles.value}>{data?.modelName}</div> */}
              <div>
                <div className={styles.key}>Brand</div>
                <div className={styles.value}>{data?.brandName}</div>
                <div className={styles.key}>Edition</div>
                <div className={styles.value}>{data?.edition}</div>
                <div className={styles.key}>Size</div>
                <div className={styles.value}>{data?.size}</div>
                <div className={styles.key}>Color</div>
                <div className={styles.value}>{data?.color}</div>
                <div className={styles.key}>Condition</div>
                <div className={styles.value}>{data?.condition}</div>
              </div>
              {/* <div>
                <div className={styles.key}>Remarks</div>
                <div className={styles.value}>{data?.remarks}</div>
              </div> */}
            </div>
          </div>

          {/* <div className={styles.flexRow}>
            <div className={styles.graphDiv}>
              <img alt="img" src="/assets/Graph.svg" />
            </div>
            <div className={styles.sneakerDetails}>
              <div>
                <div className={styles.key}>Brand</div>
                <div className={styles.value}>{data?.brandName}</div>
                <div className={styles.key}>Edition</div>
                <div className={styles.value}>{data?.edition}</div>
                <div className={styles.key}>Size</div>
                <div className={styles.value}>{data?.size}</div>
                <div className={styles.key}>Color</div>
                <div className={styles.value}>{data?.color}</div>
                <div className={styles.key}>Condition</div>
                <div className={styles.value}>{data?.condition}</div>
              </div>

              <div style={{ marginLeft: "50px" }}>
                <div className={styles.key}>Remarks</div>
                <div className={styles.value}>{data?.remarks}</div>
              </div>
            </div>
          </div>
         */}
        </div>
      </div>
    </>
  );
}
