import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { createStore, applyMiddleware } from "redux";
import { rootReducer } from "./store/reducers/Index";
import thunk from "redux-thunk";
import { Provider as AlertProvider, positions, transitions } from "react-alert";
import { Provider, useSelector } from "react-redux";

import Auth from "./components/Auth/Auth";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import Authentication from "./pages/AuthenticationPage";
import DunkAuctionPage from "./pages/DunkAuctionPage";
import LandingPage from "./pages/LandingPage";
import MyNFTsPage from "./pages/MyNFTsPage";
import ProductDetailsPage from "./pages/ProductDetailsPage";
import AlertTemplate from "./components/AlertTemplate";
import configureStore from "./store/reducers/configureStore";
import MyNFTsDetailsPage from "./pages/MyNFTsDetailsPage";
import DunkLoanPage from "./pages/DunkLoanPage";
import CreateDunkLoanPage from "./pages/CreateDunkLoanPage";
import DunkStakingPage from "./pages/DunkStakingPage";
import DunkCommunityPage from './pages/DunkCommunityPage';
import SignIn from "./components/Auth2/SignIn/SignIn";
import SignUp from "./components/Auth2/SignUp/SignUp";

const options = {
  position: positions.TOP_RIGHT,
  timeout: 5000,
  offset: "30px",
  transition: transitions.SCALE,
};

function App() {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const isVerifying = useSelector((state) => state.auth.isVerifying);

  return (
    <AlertProvider template={AlertTemplate} {...options}>
      <Router>
        <Switch>
          <Route exact path="/" component={LandingPage} />
          <Route exact path="/authentication" component={Authentication} />
          <Route exact path="/dunk-auction" component={DunkAuctionPage} />
          <Route exact path="/my-NFTs" component={MyNFTsPage} />
          <Route exact path="/dunk-loan" component={DunkLoanPage} />
          <Route exact path="/create-loan/:id" component={CreateDunkLoanPage} />
          <Route exact path="/dunk-staking" component={DunkStakingPage} />
          <Route exact path="/dunk-community" component={DunkCommunityPage} />
          <Route exact path="/signin" component={SignIn} />
          <Route exact path="/signup" component={SignUp} />
          <Route
            exact
            path="/product-details/:id"
            component={ProductDetailsPage}
          />
          <Route
            exact
            path="/myNFTs-details/:id"
            component={MyNFTsDetailsPage}
          />
          <Route exact path="/auth/:id" component={Auth} />
          <Redirect to="/" />
        </Switch>
      </Router>
    </AlertProvider>
  );
}

export default App;

// {!isAuthenticated ? (
//   <Switch>
//     <Route exact path="/auth/:id" component={Auth} />
//     <Redirect  to="/auth/signIn" />
//   </Switch>
// ) : (
//   <>
//         </>
//       )}
