import React from "react";
// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import "./slider.scss";
export default function SimpleSlider({ request }) {
  var settings = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const data = [1];
  const status = [
    "shipping",
    "delivery_complete",
    "inspection_process",
    "approved",
    "rejected",
  ];
  return (
    <Carousel showStatus={false}>
      {request.map((item) => (
        <div className="statusDiv">
          <div className="productDetails">
            <div className="imgDiv">
              <img alt="img" src={`${process.env.REACT_APP_BASE_URL}/requests/file/${item.image[0].folderName}/${item.image[0].name}`} />
            </div>
            <div className="flexCol">
              <div className="title">{item.modelName}</div>
              <div className="requestNo">Request#{item._id}</div>
              <div className="userDiv">
                <div className="owner">Owner</div>
                <div className="userName">({item.ownerAddress})</div>
              </div>
            </div>
          </div>
          <div className="productStatus">
            <div className="itemDiv">
              <div className={`itemImg inProcess activeItemImg`}>
                <img
                  src={`/assets/Shipping-${
                    item.status === "shipping" ? "white" : "white"
                  }.svg`}
                  className="passwordImg"
                />
              </div>
              <p>Shipping</p>
            </div>
            <div className="separator activeSeparator"></div>

            <div className="itemDiv">
              <div
                className={`itemImg ${
                  item.status === "shipping"
                    ? "inProcess"
                    : "activeItemImg"
                }`}
              >
                <img
                  src={`/assets/Delivery-${
                    item.status === "shipping"
                      ? "red"
                      : "white"
                  }.svg`}
                  className="passwordImg"
                />
              </div>
              <p>Delivery Completed</p>
            </div>
            <div className={`separator ${
                    item.status === "shipping"
                      ? ""
                      : "activeSeparator"
                  }`}></div>

            <div className="itemDiv">
              <div className={`itemImg ${
                  item.status === "delivery_complete"
                    ? "inProcess"
                    : item.status === "inspection_process"
                    ? "activeItemImg"
                    : ""
                }`}>
                <img
                  src={`/assets/inspection-${
                    item.status === "delivery_complete"
                      ? "red"
                      : item.status === "inspection_process"
                      ? "white"
                      : "black"
                  }.svg`}
                  className="passwordImg"
                />
              </div>
              <p>Inspection Process </p>
            </div>
            <div className={`separator ${
                    item.status === "inspection_process"
                      ? "activeSeparator"
                      : ""
                  }`}></div>

            <div className="itemDiv">
              <div className={`itemImg ${
                  item.status === "inspection_process"
                    ? "inProcess"
                    : item.status === "approved"
                    ? "activeItemImg"
                    : ""
                }`}>
                <img src={`/assets/Sneakers-${
                    item.status === "inspection_process"
                      ? "red"
                      : item.status === "approved"
                      ? "white"
                      : "black"
                  }.svg`} className="passwordImg" />
              </div>
              <p>Ready to enjoy!</p>
            </div>
          </div>
        </div>
      ))}
    </Carousel>
  );
}
