import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styles from "./DunkAuction.module.scss";
import Slider from "react-rangeslider";
import "./RangeSlider.css";
import { Link } from "react-router-dom";
import { AuctionTypes, ConditionTypeEnum } from "../../constants";
import Card from "./Card";
import instance from "../../utils/helper/http.helper";
import { toFixedNoRounding } from "../__common/FixedNoRounding";
import pic from "../../assets/show.png";
import { brand, edition, size, color } from "../../constants/constants";
import Loader from "react-loader-spinner";
export default function DunkAuction({
  applying,
  allAndMostPopular,
  handleAllAndMostPopular,
  nftOnAuction,
  search,
  oneETH,
  range,
  state,
  handleOnChange,
  selectCondition,
  setSelectCondition,
  condition,
  setCondition,
  resetAction,
  data,
  clipping,
  formik,
  handleNftLike,
  handleAuctionLike
}) {
  console.log(nftOnAuction);
  const history = useHistory();
  const [searchResults, setSearchResults] = useState(0);
  const [selectedColor, setSelectedColor] = useState("Color");
  const handleColorDrop = () => {
    let drop = document.getElementById("colOptionsDropdown");
    drop.classList.toggle("hide");
    if (drop.classList.contains("hide")) {
      drop.style.display = "flex";
    } else {
      drop.style.display = "none";
    }
  };
  console.log(search, "search search");
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        {/* <div className={styles.flexRow}> 
          <pre className={styles.flexHeading}>
            DUNK
            <div className={styles.strokedHeading}> Auctions</div>
          </pre> 
          <div className={styles.flexFilters}>
            <div className={`${styles.basicFilters} ${styles.active}`}>
              All Items
            </div>
            <div className={`${styles.basicFilters}`}>Nike</div>
            <div className={`${styles.basicFilters}`}>Adidas</div>
            <div className={`${styles.basicFilters}`}>Collab</div>
            <div className={`${styles.basicFilters}`}>Others</div>
          </div> 
        </div> 
        <div className={styles.flexRow}>
          <div className={styles.searchResult}>Search Results (200)</div>
        </div> */}
        <div className={styles.heading}>
          <div className={styles.name}>
            <div>DUNK</div>
            <div className={styles.auction}>Auctions</div>
          </div>
          {search && (
            <div className={styles.search}>Search Results({data.length})</div>
          )}
        </div>

        <div className={styles.flexRow}>
          <div className={styles.colFilters}>
            <select
              label="allAndMostPopular"
              value={allAndMostPopular}
              name="allAndMostPopular"
              onChange={handleAllAndMostPopular}
            >
              <option value="">All</option>
              <option value="Most Popular">Most Popular</option>
              <option value="Most Expensive">Most Expensive</option>
            </select>
            <div className={styles.separator}></div>
            <div className={styles.priceRange}>PRICE RANGE </div>
            <Slider
              min={range.minPrice}
              max={range.maxPrice}
              step={0.001}
              value={state}
              onChange={handleOnChange}
            />
            <div className={styles.amount}>
              <div>{range.minPrice} ETH</div>
              <div>{range.maxPrice} ETH</div>
            </div>
            <form onSubmit={formik.handleSubmit}>
              <div className={styles.input_container}>
                <div className={styles.inputDiv}>
                  <label htmlFor="brand">Brand</label>
                  <select
                    label="brand"
                    value={state.remarks}
                    name="brand"
                    id="brand"
                    {...formik.getFieldProps("brand")}
                  >
                    <option value="">Select Brand</option>
                    {brand.map((item) => (
                      <>
                        <option value={item}>{item}</option>
                      </>
                    ))}
                  </select>
                </div>
                <div className={styles.inputDiv}>
                  <label htmlFor="edition">Edition</label>
                  <select
                    label="edition"
                    value={state.remarks}
                    name="edition"
                    id="edition"
                    {...formik.getFieldProps("edition")}
                  >
                    <option value="">Select Edition</option>
                    {edition.map((item) => (
                      <>
                        <option value={item}>{item}</option>
                      </>
                    ))}
                  </select>
                </div>
                <div className={styles.inputDiv}>
                  <label htmlFor="size">Size</label>
                  <select
                    label="size"
                    value={state.remarks}
                    name="size"
                    id="size"
                    {...formik.getFieldProps("size")}
                  >
                    <option value="">Select Size</option>
                    {size.map((item) => (
                      <>
                        <option value={item}>{item}</option>
                      </>
                    ))}
                  </select>
                </div>
                <div className={styles.inputDiv}>
                  <label htmlFor="Color">Color</label>
                  <select
                    label="color"
                    value={state.color}
                    name="color"
                    id="color"
                    {...formik.getFieldProps("color")}
                  >
                    <option value="">Select Color</option>
                    {color.map((item) => (
                      <>
                        <option value={item}>{item}</option>
                      </>
                    ))}
                  </select>
                </div>
                <div className={styles.inputDiv}>
                  <label htmlFor="condition">Condition</label>
                  <select
                    label="condition"
                    value={state.remarks}
                    name="condition"
                    id="condition"
                    {...formik.getFieldProps("condition")}
                  >
                    <option value="">Select condition</option>
                    <option value={ConditionTypeEnum.new}>
                      {ConditionTypeEnum.new}
                    </option>
                    <option value={ConditionTypeEnum.worn}>
                      {ConditionTypeEnum.worn}
                    </option>
                  </select>
                </div>
              </div>
              <div className={styles.btn_container}>
                <button className={styles.button} type="submit">
                  {
                    applying ? <Loader type="Bars" color="white" height={30} width={40} />:"apply"
                  }
                  
                </button>
              </div>
            </form>
            {/*  */}
            <div className={styles.separator}></div>
            <div className={styles.resetDiv} onClick={resetAction}>
              <img alt="img" src="/assets/reset.svg" /> Reset filter
            </div>
          </div>

          <div className={styles.flexCard}>
            <div className={styles.cardDiv}>
              {data?.length > 0 ? (
                data.map((item) => (
                  <Card oneETH={oneETH} item={item} handleLike={handleAuctionLike}/>
                ))
              ) : (
                <div style={{ textAlign: "center", width: "100%" }}>
                  No data found
                </div>
              )}
            </div>
          </div>
        </div>

        <div className={styles.newWrapper}>
          <div className={styles.heading}>
            <h3>
              Most Expensive <b>NFTs</b>
            </h3>
            <p>List of most expensive shoes sold in Dunk Auction.</p>
          </div>
          <div className={styles.specialEvent}>
            {nftOnAuction && nftOnAuction?.length
              ? nftOnAuction
                  ?.sort((a, b) => (b.price > a.price ? 1 : -1))
                  .slice(0, 3)
                  .map((item) => <Card oneETH={oneETH} item={item} handleLike={handleNftLike}/>)
              : null}
          </div>
        </div>

        <div className={styles.newWrapper}>
          <div className={styles.heading}>
            <h3>
              Most Liked <b>Shoes</b>
            </h3>
            <p>List of most Liked shoes sold in Dunk Auction.</p>
          </div>
          <div className={styles.specialEvent}>
            {nftOnAuction && nftOnAuction?.length
              ? nftOnAuction
                  ?.sort((a, b) => (b.likesCount > a.likesCount ? 1 : -1))
                  .slice(0, 3)
                  .map((item) => <Card oneETH={oneETH} item={item} handleLike={handleNftLike}/>)
              : null}
          </div>
        </div>
      </div>
    </div>
  );
}