import React, { useState, useEffect } from "react";
import Web3 from "web3";
import { useParams } from "react-router";
import { useAlert } from "react-alert";
import Loader from "react-loader-spinner";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import styles from "../components/__common/NftDetails/NftDetails.module.scss";
import instance from "../utils/helper/http.helper";
import {
  getTier,
  makeBid,
  tokenDetail,
  acceptBid,
  callAuction,
  closeAuction,
} from "../services/NFTCreation.service";
import { getETHPrice } from "../utils/helper/ethPrice";
import { AuctionTypes } from "../constants";
export default function useNftDetails() {
  const history = useHistory();
  let location = useLocation();
  const alert = useAlert();
  const {
    selected: { address, isConnected },
  } = useSelector((state) => state.wallet);
  const { id } = useParams();
  const [activeIndex, setActiveIndex] = useState(0);

  const [bidPrice, setBidPrice] = useState("0");
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState({});
  const [bidHistory, setBidHistory] = useState([]);
  const [trdHistory, setTrdHistory] = useState([]);
  const [details, setDetails] = useState({
    availability: false,
    bidWinner: "0x0000000000000000000000000000000000000000",
    _bidCount: "0",
    _maxBid: "0",
    _maxBidder: "0x0000000000000000000000000000000000000000",
    _startAmount: "0",
  });
  const [isWithdrawing, setIsWithdrawing] = useState(false);
  const [isBiding, setIsBiding] = useState(false);
  const [isAccepting, setIsAccepting] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [isStarting, setIsStarting] = useState(false);
  const [startPrice, setStartPrice] = useState("0");
  const [relatedNFTs, setRelatedNFTs] = useState([]);
  const [oneETH, setOneETH] = useState(0);
  const [tear, setTear] = useState("0");
  const getButtons = () => {
    if (location.pathname.includes("myNFTs-details")) {
      return (
        <>
          {details.availability ? (
            <button
              className={`${styles.actionButton} ${styles.activeButton}`}
              onClick={closeActionAction}
            >
              {isClosing ? (
                <Loader type="Bars" color="black" height={30} width={40} />
              ) : (
                <>
                  Close Auction{" "}
                  <img alt="img" src="/assets/arrow-left-on.svg" />{" "}
                </>
              )}
            </button>
          ) : (
            <button
              className={`${styles.actionButton} ${styles.activeButton}`}
              onClick={handleConnectOpen}
              disabled={details.availability ? true : false}
            >
              {isStarting ? (
                <Loader type="Bars" color="black" height={30} width={40} />
              ) : (
                <>
                  Start Auction{" "}
                  <img alt="img" src="/assets/arrow-left-on.svg" />{" "}
                </>
              )}
            </button>
          )}
          {details.availability ? (
            <button
              className={`${styles.actionButton}`}
              onClick={acceptBidAction}
            >
              {isAccepting ? (
                <Loader type="Bars" color="black" height={30} width={40} />
              ) : (
                <>
                  Accept Bid <img alt="img" src="/assets/arrow-left-2.2.svg" />{" "}
                </>
              )}
            </button>
          ) : null}
        </>
      );
    } else {
      return (
        <>
          {data?.ownerAddress != address && (
            <button
              className={`${styles.actionButton} ${styles.activeButton}`}
              onClick={handleConnectOpen}
            >
              {isBiding ? (
                <Loader type="Bars" color="black" height={30} width={40} />
              ) : (
                <>Make Bid{" >"}</>
              )}
            </button>
          )}
        </>
      );
    }
  };
  console.log(location);
  const getAll = async () => {
    return await instance.get("/requests?status=approved");
  };
  const getTierAsync = async () => {
    const res = await getTier();
    console.log(res);
    setTear(res);
  };
  const getTime = (createdAt) => {
    var date = new Date(createdAt);
    return date.toDateString();
  };
  const getImages = (ar) => {
    let temp = [];
    for (let i = 0; i < ar.length; i++) {
      temp.push({
        original: `${process.env.REACT_APP_BASE_URL}/requests/file/${ar[i].folderName}/${ar[i].name}`,
        thumbnail: `${process.env.REACT_APP_BASE_URL}/requests/file/${ar[i].folderName}/${ar[i].name}`,
      });
    }
    return temp;
  };
  useEffect(() => {
    getETHPrice().then((res) => {
      console.log(res.data.ethereum.usd);
      setOneETH(res.data.ethereum.usd);
    });
    getAll().then((res2) => {
      console.log(res2, "GET RESULT");
      setRelatedNFTs(res2.data.data);
    });
    getTierAsync();
  }, []);

  const getApproved = async () => {
    const res2 = await instance.get(`/requests/${id}`);
    return res2;
  };
  const clipping = (string) => {
    if (string.length > 20) {
      const new_string = string.substring(0, 20) + ".....";
      return new_string;
    }
    return string;
  };
  const getBids = async (id) => {
    return await instance.get(`/bidding/bids/${id}`);
  };
  const getTrd = async (id) => {
    const res = await instance.get(`/trading-history/${id}`);

    return res.data.data;
  };
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    tokenInfoAsync();
  }, [id]);
  const tokenInfoAsync = () => {
    getApproved().then((res2) => {
      console.log(res2.data.data, "GET APPROVED RESULT");
      setData({ ...res2.data.data });
      if (res2.data.data.tokenId) {
        getBids(res2.data.data.auctionId).then((bid) => {
          console.log(bid, "getBids");
          setBidHistory([...bid.data.data]);
        });
        getTrd(res2.data.data.tokenId).then((trdHistory) => {
          console.log(trdHistory, "//////////////");
          setTrdHistory([...trdHistory]);
        });
        tokenDetail(res2.data.data.tokenId).then((res) => {
          console.log(res);
          setDetails(res);
        });
      }
    });
  };
  const createBid = async (payload) => {
    return await instance.post("/bidding", payload);
  };
  const makeBidAction = async () => {
    setIsBiding(true);
    handleConnectOpen();
    try {
      const res = await makeBid(data.tokenId, bidPrice);
      console.log(res);
      if (res?.status) {
        setIsBiding(false);

        const res2 = await createBid({
          bidAmount: +bidPrice,
          request: data._id,
          auctionId: data.auctionId,
          bidderAddress: address,
          requestOwnerAddress: data.ownerAddress,
        });
        if (res2.data.success) {
          alert.show("Bid successful.", { type: "success" });
        }
        tokenInfoAsync();
        console.log(res, "createBid");
      } else {
        setIsBiding(false);
        alert.show("Please connect your wallet.", { type: "error" });
      }
    } catch (error) {
      setIsBiding(false);
      alert.show("Something went wrong.", { type: "error" });
    }
  };

  const handleLike = async () => {
    const res = await instance.post(`requests/like/${id}`, {
      address: address,
    });

    setData({ ...res.data.data });
  };
  const updateAuction = async (type, addresss) => {
    if (addresss) {
      return await instance.patch(`/requests/auction/${data._id}`, {
        auction: type,
        ownerAddress: addresss,
      });
    } else {
      return await instance.patch(`/requests/auction/${data._id}`, {
        auction: type,
        price: +startPrice,
      });
    }
  };
  const closeActionAction = async () => {
    if (isConnected) {
      setIsClosing(true);
      const res = await closeAuction(data.tokenId);
      if (res?.status) {
        const res2 = await updateAuction(AuctionTypes.off);
        await instance.delete(`/bidding/${data.auctionId}`);
        alert.show("Auction off successfully", { type: "success" });
        tokenInfoAsync();
        setIsClosing(false);
        console.log(res, "updateAuction");
      } else {
        setIsClosing(false);
        alert.show("Something went wrong.", { type: "error" });
      }
      setStartPrice("0");
    } else {
      setIsClosing(false);
      setStartPrice("0");
      alert.show("Please connect your wallet", { type: "error" });
    }
  };
  const startActionAction = async () => {
    handleConnectOpen();
    setIsStarting(true);
    if (Number(startPrice) > 0) {
      if (isConnected) {
        try {
          const res = await callAuction(data.tokenId, startPrice);
          if (res?.status) {
            setIsStarting(false);
            const res2 = await updateAuction(AuctionTypes.on);
            alert.show("Auction started successfully", { type: "success" });
            tokenInfoAsync();
            console.log(res, "updateAuction");
          } else {
            setIsStarting(false);
            alert.show("Something went wrong.", { type: "error" });
          }
          setStartPrice("0");
        } catch (error) {
          setIsStarting(false);
          setStartPrice("0");
          if (error.message.includes("to wei, too many decimal places")) {
            alert.show("Too many decimal places", { type: "error" });
          } else {
            alert.show("Something went wrong.", { type: "error" });
          }
        }
      } else {
        setIsStarting(false);
        setStartPrice("0");
        alert.show("Please connect your wallet", { type: "error" });
      }
    } else {
      setIsStarting(false);
      setStartPrice("0");
      alert.show("Please enter an valid start amount", { type: "info" });
    }
  };
  const handleConnectOpen = () => {
    setIsOpen(!isOpen);
    setStartPrice("0");
  };
  const acceptBidAction = async () => {
    setIsAccepting(true);
    try {
      const detail = await tokenDetail(data.tokenId);
      const res = await acceptBid(data.tokenId);
      if (res?.status) {
        try {
          const res2 = await updateAuction(
            AuctionTypes.close,
            detail._maxBidder
          );

          await instance.delete(`/bidding/${data.auctionId}`);
          await instance.post("/trading-history", {
            tokenId: String(data.tokenId),
            to: String(detail._maxBidder),
            from: String(data.ownerAddress),
            price: Number(Web3.utils.fromWei(detail._maxBid || "0", "ether")),
          });
          console.log(res2);
          history.push(`/my-NFTs`);
          alert.show("Successfully closed", { type: "success" });
          tokenInfoAsync();
        } catch (error) {
          alert.show("Something went wrong", { type: "error" });
        }
      }
      setIsAccepting(false);
    } catch (error) {
      setIsAccepting(false);
      alert.show("Something went wrong", { type: "error" });
    }
  };
  console.log(bidHistory);
  return {
    data,
    getImages,
    clipping,
    address,
    handleLike,
    details,
    oneETH,
    getButtons,
    bidHistory,
    getTime,
    trdHistory,
    isOpen,
    handleConnectOpen,
    startPrice,
    setStartPrice,
    startActionAction,
    tear,
    setBidPrice,
    makeBidAction,
    history,
  };
}
