import React, { useEffect } from "react";
import DunkAuction from "../components/DunkAuction/DunkAuction";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import DunkStaking from "../components/DunkStaking/DunkStaking";
import Section1 from "../components/__common/Section1/Section1";
import Section7 from "../components/LandingPage/Section7/Section7";
import Section3 from '../components/__common/Section3/Section3'

export default function DunkStakingPage() {
  useEffect(()=>{
    window.scrollTo({ top: 0, behavior: 'smooth' })
},[])
  return (
    <>
    <Header/>
      
      <Section1
        heading="DUNK STAKING"
        features="Stake DUNK to Earn"
      />
      <DunkStaking/>
      <Section7/>
      <Footer />
    </>
  );
}
