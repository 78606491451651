import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import Web3 from "web3";
import styles from "./NftDetails.module.scss";
import heart from "../../../assets/heart-icon.svg";
import unlike from "../../../assets/unlike-icon.svg";
import arrowUp from "../../../assets/arrow-down.svg";
import value from "../../../assets/value-icon.svg";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { toFixedNoRounding } from "../../__common/FixedNoRounding";
import RelatedNFTs from "../RelatedNFs/RelatedNFTs";
import useNftDetails from "../../../hooks/UseNftDetails";

export default function NftDetails() {
   const {data,
    getImages,
    clipping,
    address,
    handleLike,
    details,
    oneETH,
    getButtons,
    bidHistory,
    getTime,
    trdHistory,
    isOpen,
    handleConnectOpen,
    startPrice,
    setStartPrice,
    startActionAction,
    tear,
    setBidPrice,
    makeBidAction,history}= useNftDetails()
  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <div className={styles.flexRow}>
            <div className={styles.productImgDiv}>
              {data?.image ? (
                <>
                  <ImageGallery
                    items={getImages(data.image)}
                    originalHeight="200px"
                    showNav={false}
                    showFullscreenButton={false}
                    showPlayButton={false}
                  />
                </>
              ) : null}
              <div></div>
            </div>
            <div className={styles.productDetailsDiv}>
              <div className={styles.modalName}>
                {data?.modelName && clipping(data?.modelName)}
              </div>
              <div className={styles.usersDetails}>
                <div className={`${styles.profileImg} ${styles.ownedBy}`}>
                  Owned by <span>{data?.ownerAddress?.substring(0, 6)}</span>
                </div>
                <div className={styles.favorite}>
                  {address && (
                    <>
                      <img
                        onClick={handleLike}
                        src={
                          data?.likes?.find((item) => item === address)
                            ? heart
                            : unlike
                        }
                        alt="heart icon"
                        style={{
                          height: "35px",
                          width: "34px",
                          cursor: "pointer",
                        }}
                      />
                      {data?.likes?.length} Favourite
                    </>
                  )}
                </div>
              </div>
              <div className={styles.currentPrice}>
                <h4>Last Bid Price</h4>
                <div className="imgWrapper">
                  <img src={value} alt="" />
                  <span>
                    {Web3.utils.fromWei(details._maxBid || "0", "ether")} ($
                    {toFixedNoRounding(
                      Web3.utils.fromWei(details._maxBid || "0", "ether") *
                        oneETH,
                      2
                    ) || 0}
                    ){" "}
                  </span>
                </div>
              </div>
              <div className={styles.currentPrice}>
                <h4>Initial Auction Price</h4>
                <div className="imgWrapper">
                  <img src={value} alt="" />
                  <span>
                    {Web3.utils.fromWei(details._startAmount || "0", "ether")}{" "}
                    ($
                    {toFixedNoRounding(
                      Web3.utils.fromWei(details._startAmount || "0", "ether") *
                        oneETH,
                      2
                    ) || 0}
                    ){" "}
                  </span>
                </div>
              </div>
              <div className={styles.flexBid}>
                <div>
                  {Web3.utils.fromWei(details._maxBid || "0", "ether")}{" "}
                  <p>Last Bid</p>
                </div>
                <div>
                  {details._maxBidder.substr(0, 4)}
                  <p>Last Bidder</p>
                </div>
                <div>
                  {details._bidCount}
                  <p>Bid Counter</p>
                </div>
              </div>
              <div className={styles.row}>
                  {
                      getButtons()
                  }
                {/* {data?.ownerAddress != address && (
                  <button
                    className={`${styles.actionButton} ${styles.activeButton}`}
                    onClick={handleConnectOpen}
                  >
                    {isBiding ? (
                      <Loader
                        type="Bars"
                        color="black"
                        height={30}
                        width={40}
                      />
                    ) : (
                      <>Make Bid{" >"}</>
                    )}
                  </button>
                )}
                {false && details.availability ? (
                  <button
                    className={`${styles.actionButton} ${styles.activeButton}`}
                    onClick={closeActionAction}
                  >
                    {isClosing ? (
                      <Loader
                        type="Bars"
                        color="black"
                        height={30}
                        width={40}
                      />
                    ) : (
                      <>
                        Close Auction{" "}
                        <img alt="img" src="/assets/arrow-left-on.svg" />{" "}
                      </>
                    )}
                  </button>
                ) : (
                  false && (
                    <button
                      className={`${styles.actionButton} ${styles.activeButton}`}
                      onClick={handleConnectOpen}
                      disabled={details.availability ? true : false}
                    >
                      {isStarting ? (
                        <Loader
                          type="Bars"
                          color="black"
                          height={30}
                          width={40}
                        />
                      ) : (
                        <>
                          Start Auction{" "}
                          <img alt="img" src="/assets/arrow-left-on.svg" />{" "}
                        </>
                      )}
                    </button>
                  )
                )}
                {false && details.availability ? (
                  <button
                    className={`${styles.actionButton}`}
                    onClick={acceptBidAction}
                  >
                    {isAccepting ? (
                      <Loader
                        type="Bars"
                        color="black"
                        height={30}
                        width={40}
                      />
                    ) : (
                      <>
                        Accept Bid{" "}
                        <img alt="img" src="/assets/arrow-left-2.2.svg" />{" "}
                      </>
                    )}
                  </button>
                ) : null} */}
              </div>

              {/* previous work */}
            </div>
          </div>

          <div className={styles.flexRow}>
            <div className={styles.detailsWrapper}>
              <div className={styles.details}>
                <div className={styles.heading}>
                  Details
                  <img src={arrowUp} alt="arrowUp" />
                </div>
                <div className={styles.userInfo}>
                  {/* <img src={user} alt="" /> */}
                  <span className={styles.createdBy}>Created by</span>
                  <span className={styles.userName}>
                    {data?.ownerAddress?.substring(0, 6)}
                  </span>
                </div>
                <p>Remarks: {data?.remarks}</p>
              </div>
              <div className={styles.chainInfo}>
                <div className={styles.heading}>
                  Chain Info
                  <img src={arrowUp} alt="arrowUp" />
                </div>
                <div className={styles.contact}>
                  Contract Address
                  <pre>0x526cAb1168Fb35b635c61Cb32cac51b872ccf8b8</pre>
                </div>
                <div className={styles.blockChain}>
                  Blockchain
                  <pre>Ethereum</pre>
                </div>
              </div>
            </div>
            <div className={styles.sneakerDetails}>
              <div>
                <div className={styles.key}>Brand</div>
                <div className={styles.value}>{data?.brandName}</div>
                <div className={styles.key}>Edition</div>
                <div className={styles.value}>{data?.edition}</div>
                <div className={styles.key}>Size</div>
                <div className={styles.value}>{data?.size}</div>
                <div className={styles.key}>Color</div>
                <div className={styles.value}>{data?.color}</div>
                <div className={styles.key}>Condition</div>
                <div className={styles.value}>{data?.condition}</div>
              </div>
              {/* <div>
                <div className={styles.key}>Remarks</div>
                <div className={styles.value}>{data?.remarks}</div>
              </div> */}
            </div>
          </div>

          {/* Trading History, Offers, Details */}

          <div className={styles.flexTableRow}>
            <div className={styles.offerWrapper}>
              <div className={styles.offers}>
                <div className={styles.heading}>
                  Offers
                  <img src={arrowUp} alt="arrowUp" />
                </div>
                <table className={styles.offerTable}>
                  {bidHistory.map((item) => (
                    <tr>
                      <td>{item.bidderAddress}</td>
                      <td>{item.bidAmount}</td>
                      <td>{getTime(item.createdAt)}</td>
                      <td>
                        <button>Offered {">"}</button>
                      </td>
                    </tr>
                  ))}
                </table>
              </div>
              <div className={styles.tradingHistory}>
                <div className={styles.heading}>
                  Trading History
                  <img src={arrowUp} alt="arrowUp" />
                </div>
                <table className={styles.historyTable}>
                  {trdHistory.length ? (
                    <tr className={styles.tableHeader}>
                      <th>Event</th>
                      <th>Price</th>
                      <th>From</th>
                      <th>To</th>
                      <th>Date</th>
                    </tr>
                  ) : null}
                  {trdHistory.map((item) => (
                    <tr>
                      <td>
                        <img src="" alt="" />
                        Offer
                      </td>
                      <td>{item.price}</td>
                      <td>
                        {/* <img src={user} alt="" /> */}
                        {item.from}
                      </td>
                      <td>{item.to}</td>
                      <td>{getTime(item.createdAt)}</td>
                    </tr>
                  ))}
                </table>
              </div>
            </div>
          </div>

          <RelatedNFTs
            history={history}
            heading={"Related"}
            boldHeading={"NFTs"}
            para={"Check! We've got other options for you"}
          />
        </div>
      </div>

      <Modal
        isOpen={isOpen}
        onRequestClose={handleConnectOpen}
        contentLabel="HOGI Modal"
        className={styles.modal}
        overlayClassName={styles.modalOverlay}
      >
        {/* Modal Content Here */}
        <div className={styles.modalContent}>
          <div className={styles.header}>
            <div className={styles.cancel}>
              <img
                onClick={handleConnectOpen}
                src="/assets/alert/Cross.svg"
                alt="cancel"
              />
            </div>
          </div>
          {!details.availability ? (
            <div className={styles.modelBody}>
              <div className={styles.inputDiv}>
                <input
                  type="number"
                  placeholder="Starting Price"
                  onChange={(e) => setStartPrice(e.target.value)}
                />
                <div className={styles.currency}>ETH</div>
              </div>
              <div className={styles.usdValDiv}>
                <div>USD value</div>
                <div>{startPrice * oneETH}</div>
              </div>
              <button onClick={startActionAction}>Submit</button>
            </div>
          ) : (
            <div className={styles.modelBody}>
              {Number(tear) >= data.tier ? (
                <>
                  <div className={styles.inputDiv}>
                    <input
                      type="number"
                      placeholder="Bid Price"
                      onChange={(e) => setBidPrice(e.target.value)}
                    />
                    <div className={styles.currency}>ETH</div>
                  </div>
                  <button onClick={makeBidAction}>Submit</button>
                </>
              ) : (
                <div className={styles.errorMessage}>
                  **You do not qualify for the TIER to purchase this NFT. Please
                  check your TIER first.**
                </div>
              )}
            </div>
          )}
        </div>
      </Modal>
    </>
  );
}
