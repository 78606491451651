import React, { useEffect } from "react";
import Section1 from "../components/__common/Section1/Section1";
import Authentication from "../components/Authentication/Authentication";
import Section3 from "../components/__common/Section3/Section3";
import Section7 from "../components/LandingPage/Section7/Section7";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";

export default function AuthenticationPage() {
    useEffect(()=>{
        window.scrollTo({ top: 0, behavior: 'smooth' })
    },[])
  return (
    <>
      <Header />
      {/* <Section1
        heading="Authentication"
        features="Fast, Perfect Authentication Service You’ve Never Had"
      /> */}
      <Authentication />
      {/* <Section3 /> */}
      <Section7/>
      <Footer />
    </>
  );
}
