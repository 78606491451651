import { ERC20Contracts } from "./constants/contracts";
import { DunkNFTAbi } from "./abi/DunkNFT.abi";
import { DunkPlatformAbi } from "./abi/DunkPlatform.abi";
import { DunkTokenAbi } from "./abi/DunkToken.abi";

export interface IContractLookup {
  contractName: string;
  contractAddress: string;
  contractAbi: any;
}

export const ContractLookup: IContractLookup[] = [
  {
    contractName: ERC20Contracts.DUNKPLATFORM,
    contractAddress: "0x866A6F35DeE5B1547AC65Db2A67411a8bE8896D4",
    contractAbi: DunkPlatformAbi,
  },
  {
    contractName: ERC20Contracts.DunkNFT,
    contractAddress: "0xcAf9A9870F6705266183c7ac916f2CC2ee254822",
    contractAbi: DunkNFTAbi,
  },
  {
    contractName: ERC20Contracts.DUNKToken,
    contractAddress: "0xf84B9C2c67AC0bCe5804359234f5439d80587818",
    contractAbi: DunkTokenAbi,
  },
];
