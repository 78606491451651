import { Link, useHistory, useLocation } from "react-router-dom";
import React, { useState } from "react";
import styles from "./Header.module.scss";
import { slide as Menu } from "react-burger-menu";
import "./HeaderBM.scss";
import { useSelector } from "react-redux";
function Header() {
  const location = useLocation();
  console.log(location);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const {
    selected: { address },
  } = useSelector((state) => state.wallet);
  const [menuOpenState, setMenuOpenState] = useState(false);
  const stateChangeHandler = (newState) => setMenuOpenState(newState.isOpen);
  const handleMenu = () => {
    setMenuOpenState(!menuOpenState);
  };
  const history = useHistory();
  return (
    <>
      <Menu
        right
        disableAutoFocus
        customBurgerIcon={false}
        isOpen={menuOpenState}
        onStateChange={(state) => stateChangeHandler(state)}
        itemListElement="div"
      >
        <div onClick={handleMenu} className="bm-item">
          <div className="headerLogo">
            <img alt="img" src="/assets/logo.svg" />
          </div>
        </div>
        <div onClick={handleMenu} className="bm-item">
          <div className="navItems">Authentication</div>
        </div>
        <div onClick={handleMenu} className="bm-item">
          <div className="navItems">DUNK Auction</div>
        </div>
        <div onClick={handleMenu} className="bm-item">
          <div className="navItems">My NFTs</div>
        </div>
        <div onClick={handleMenu} className="bm-item">
          <div className="navItems">DUNK Community</div>
        </div>
        <div onClick={handleMenu} className="bm-item">
          <div className="navItems">DUNK Lottery</div>
        </div>
        <div onClick={handleMenu} className="bm-item">
          <div className="navItems">DUNK STAKING</div>
        </div>
        {!isAuthenticated ? (
          <>
            <div onClick={handleMenu} className="bm-item">
              <div
                className="signInDiv"
                onClick={() => history.push("/auth/signIn")}
              >
                <div className="signIn">DUNK Wallet</div>
              </div>
            </div>
            <div onClick={handleMenu} className="bm-item">
              <div
                className="signInDiv"
                onClick={() => history.push("/auth/signUp")}
              >
                <div className="signIn">Go to Auction</div>
              </div>
            </div>
          </>
        ) : null}
      </Menu>
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <div className={styles.logo}>
            <Link to="/">
              <img alt="img" src="/assets/logo.svg" alt="logo" />
            </Link>
          </div>

          <div className={styles.burgerMenu} onClick={handleMenu}>
            <img alt="img" src="/assets/menu_icon.svg" alt="menu" />
          </div>
          <div className={styles.webNavItemsDiv}>
            <div
              className={`${styles.webNavItems} ${
                location.pathname === "/authentication" ? styles.active : ""
              }`}
              onClick={() => history.push("/authentication")}
            >
              Authentication
            </div>
            <div
              className={`${styles.webNavItems} ${
                location.pathname === "/dunk-auction" ? styles.active : ""
              }`}
              onClick={() => history.push("/dunk-auction")}
            >
              DUNK Auction
            </div>
            <div
              className={`${styles.webNavItems} ${
                location.pathname === "/my-NFTs" ? styles.active : ""
              }`}
              onClick={() => history.push("/my-NFTs")}
            >
              My NFTs
            </div>
            <div
              className={`${styles.webNavItems} ${
                location.pathname === "/dunk-community" ? styles.active : ""
              }`}
              onClick={() => history.push("/dunk-community")}
            >
              DUNK Community
            </div>
            <div
              className={`${styles.webNavItems} ${
                location.pathname === "/dunk-loan" ? styles.active : ""
              }`}
              onClick={() => history.push("/dunk-loan")}
            >
              DUNK Lottery
            </div>
            <div
              className={`${styles.webNavItems} ${
                location.pathname === "/dunk-staking" ? styles.active : ""
              }`}
              onClick={() => history.push("/dunk-staking")}
            >
              DUNK STAKING
            </div>
          </div>
          <div className={styles.authDiv}>
            {!isAuthenticated ? (
              <>
                <div className={`${styles.webSignInDiv} ${styles.marginDiv}`}>
                  <button onClick={() => history.push("/auth/signIn")}>
                    Connect Wallet
                  </button>
                </div>
                {/* <div className={styles.webSignInDiv}>
                  <button onClick={() => history.push("/auth/signUp")}>
                    Go to Auction
                  </button>
                </div> */}
              </>
            ) : (
              <div className={styles.connectWallet}>
                <img src="/assets/wallet.svg" alt="wallet" />
                Wallet Connected ({address.substring(0, 4)})
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
