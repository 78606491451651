import styles from './TireBox.module.scss';
import React, {useState} from 'react'
const TireBox = (props) => {
    // const [dunkStaked, setDunkStaked] = useState(0);

    return (
        <div className={styles.wrapper}>
            <div className={styles.container} style= {{
                    background: `${props.bg}`
                }}>
                <div className={styles.tire} style={{
                    color: `${props.color}`
                }}> {props.tire} </div>
                <div className={styles.dunkStakedValue} > {props.value} DUNK</div>
                <div className={styles.dunkStaked}>DUNK Staked</div>
            </div>
        </div>
    );
}

export default TireBox;