import React from 'react';
import styles from './DunkCommunity.module.scss';
import pic from '../../assets/section1.png';
const DunkCommunity = () => {
   return(
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <div className={styles.comingSoon}>COMMING SOON!</div>
                <div className={styles.imgDiv}>
                    <img src={pic} alt="Air Show" />
                </div>
            </div>
        </div>
   );
}

export default DunkCommunity;