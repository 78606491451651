import React, { useState, useEffect } from "react";
import DunkAuction from "../components/DunkAuction/DunkAuction";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import Section1 from "../components/__common/Section1/Section1";
import styles from "../components/__common/Section1/Section1.module.scss";
import Section7 from "../components/LandingPage/Section7/Section7";
import Section3 from "../components/__common/Section3/Section3";
import useDunkAuction from "../hooks/DunkAuction";
import Loader from "react-loader-spinner";

export default function DunkAuctionPage() {
  const {
    applying,
    allAndMostPopular,
    handleAllAndMostPopular,
    nftOnAuction,
    oneETH,
    search,
    handleSearch,
    setSearch,
    isSearching,
    range,
    state,
    handleOnChange,
    selectCondition,
    setSelectCondition,
    condition,
    setCondition,
    resetAction,
    data,
    clipping,
    formik,
    handleNftLike,
    handleAuctionLike,
  } = useDunkAuction();
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  console.log(search);
  return (
    <>
      <Header />

      <Section1
        heading="Dunk Auction"
        features="Enjoy Fake Free,  Best Deals on DUNK Auction Service"
      >
        <div className={styles.searchDiv}>
          <div className={styles.passwordField}>
            <input
              type="text"
              placeholder="Brand, Product Serial Code, NFT code, etc"
              name="repeatPassword"
              className={`${styles.blueField}`}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <img
              alt="img"
              src="/assets/search.3.svg"
              className={styles.passwordImg}
            />
          </div>
          <button
            className={styles.button}
            onClick={handleSearch}
            disabled={isSearching ? true : false}
          >
            {isSearching ? (
              <Loader type="Bars" color="white" height={30} width={40} />
            ) : (
              <>
                Search <img alt="img" src="/assets/arrow-left-2.svg" />
              </>
            )}
          </button>
        </div>
      </Section1>
      <DunkAuction
        applying={applying}
        allAndMostPopular={allAndMostPopular}
        handleAllAndMostPopular={handleAllAndMostPopular}
        nftOnAuction={nftOnAuction}
        search={search}
        oneETH={oneETH}
        range={range}
        state={state}
        handleOnChange={handleOnChange}
        selectCondition={selectCondition}
        setSelectCondition={setSelectCondition}
        condition={condition}
        setCondition={setCondition}
        resetAction={resetAction}
        data={data}
        clipping={clipping}
        formik={formik}
        handleNftLike={handleNftLike}
        handleAuctionLike={handleAuctionLike}
      />
      {/* <Section3 /> */}
      <Section7 />
      <Footer />
    </>
  );
}
