import axios from "axios";
import React, { useState, useEffect } from "react";
import { getETHPrice } from "../utils/helper/ethPrice";
import instance from "../utils/helper/http.helper";
import { useFormik } from "formik";
import * as Yup from "yup";

export default function useDunkAuction() {
  const [search, setSearch] = useState("");
  const [oneETH, setOneETH] = useState(0);
  const [allAndMostPopular, setAllAndMostPopular] = useState("");
  const [state, setstate] = useState(0);
  const [data, setData] = useState([]);
  const [nftOnAuction, setNftOnAuction] = useState([]);
  const [range, setRange] = useState({
    maxPrice: 0,
    minPrice: 0,
  });
  const [condition, setCondition] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [selectCondition, setSelectCondition] = useState(false);
  const [applying, setApplying] = useState(false);
  

  const handleOnChange = (value) => {
    setstate(value);
    console.log(value);
  };
  const getAllAuctionNFT = async (query) => {
    
    const res2 = await instance.get(`/requests/auctions/all`);
    setNftOnAuction(res2.data.data);
    console.log(res2);
  };
  const handleNftLike = async (id, address) => {
    
    const res = await instance.post(`requests/like/${id}`, {
      address: address,
    });
    const index = nftOnAuction.findIndex((obj) => obj._id === id);
    let newArray = [...nftOnAuction];
    newArray[index] = res.data.data;
    setNftOnAuction([...newArray]);
  };
  const handleAuctionLike = async (id, address) => {
    
    const res = await instance.post(`requests/like/${id}`, {
      address: address,
    });
    const index = data.findIndex((obj) => obj._id === id);
    let newArray = [...data];
    newArray[index] = res.data.data;
    setData([...newArray]);
  };
  const getAuction = async (query) => {
    const res2 = await instance.get(
      `/requests/auctions/all${query ? "?" + query : ""}`
    );
    return res2;
  };
  const getPriceRange = async () => {
    const res2 = await instance.get(`/requests/price/range`);
    return res2;
  };
  const handleSearch = () => {
    try {
      setIsSearching(true);
      getAuction(createQuery()).then((res2) => {
        console.log(res2, "GET Auction RESULT");
        setData(res2.data.data);
        setIsSearching(false);
      });
    } catch (error) {
      setIsSearching(false);
    }
  };
  useEffect(() => {
    
    getAllAuctionNFT();
    if (search === "") {
      getAuction(createQuery()).then((res2) => {
        console.log(res2, "GET Auction RESULT");
        setData(res2.data.data);
      });
    }
  }, []);
  useEffect(() => {
    getETHPrice().then((res) => {
      console.log(res.data.ethereum.usd);
      setOneETH(res.data.ethereum.usd);
    });
    getPriceRange().then((res) => {
      if (res.data.data.length > 0) {
        setRange(res.data.data[0]);
      }
      console.log(res);
    });
  }, []);
  const clipping = (string) => {
    if (string.length > 20) {
      const new_string = string.substring(0, 20) + ".....";
      return new_string;
    }
    return string;
  };

  const resetAction = () => {
    
    setstate(0);
    setCondition("");
    formik.resetForm();
    getAuction(createQuery("reset")).then((res2) => {
      console.log(res2, "GET Auction RESULT");
      setData(res2.data.data);
    });
  };
  const createQuery = (values) => {
    let query = "";
    
    console.log(state);
    if (values != "reset") {
      query = `${search ? `search=${search}` : ""}${
        state > 0 && values
          ? `${search ? "&" : ""}priceFrom=${range.minPrice}&priceTo=${state}`
          : state > 0 && !values
          ? `${search ? "&" : ""}priceFrom=${range.minPrice}&priceTo=${state}`
          : ""
      }${
        values
          ? `${condition || state > 0 || search ? "&" : ""}${
              values.brand ? `brandName=${values.brand || ""}` : ""
            }${values.brand ? "&" : ""}${
              values.condition ? `condition=${values.condition || ""}` : ""
            }${values.condition ? "&" : ""}${
              values.size ? `size=${values.size || ""}` : ""
            }${values.size ? "&" : ""}${
              values.color ? `color=${values.color || ""}` : ""
            }${values.color ? "&" : ""}${
              values.edition ? `edition=${values.edition || ""}` : ""
            }`
          : ""
      }`;
    } else {
      query = `${search ? `search=${search}` : ""}`;
    }
    // if (search) {
    //   query = `search=${search}`;
    // } else {
    //   query = `condition=${condition}&priceFrom=${range.minPrice}&priceTo=${state}`;

    // }
    return query;
  };
  const initialState = {
    brand: "",
    condition: "",
    color: "",
    edition: "",
    size: "",
  };
  const formik = useFormik({
    initialValues: initialState,
    onSubmit: (values) => {
      try {
        setApplying(true)
      console.log(values);
      console.log(createQuery(values));
      getAuction(createQuery(values)).then((res2) => {
        console.log(res2, "GET Auction RESULT");
        setData(res2.data.data);
        setApplying(false)
      });
      } catch (error) {
        setApplying(false)
      }
    },
  });
  const handleAllAndMostPopular = (e) => {
    let value = e.target.value;
    setAllAndMostPopular(value);
    
    if (value === "Most Popular") {
      let res = data.sort((a, b) => (b.likesCount > a.likesCount ? 1 : -1));
      setData(res);
    }else if(value === "Most Expensive"){
      let res = data.sort((a, b) => (b.price > a.price ? 1 : -1));
      setData(res);
    }
  };
  return {
    applying,
    allAndMostPopular,
    handleAllAndMostPopular,
    nftOnAuction,
    oneETH,
    handleSearch,
    search,
    setSearch,
    isSearching,
    range,
    state,
    handleOnChange,
    selectCondition,
    setSelectCondition,
    condition,
    setCondition,
    resetAction,
    data,
    clipping,
    formik,
    handleNftLike,
    handleAuctionLike,
  };
}
