import React, { useEffect } from "react";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import Section1 from "../components/LandingPage/Section1/Section1";
import Section2 from "../components/LandingPage/Section2/Section2";
import Section3 from "../components/LandingPage/Section3/Section3";
import Section4 from "../components/LandingPage/Section4/Section4";
import Section5 from "../components/LandingPage/Section5/Section5";
import Section6 from "../components/LandingPage/Section6/Section6";
import Section7 from "../components/LandingPage/Section7/Section7";
function LandingPage() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <React.Fragment>
      <Header />
      {/* <div style={{
        border: '1px dashed #999999',
        height: '2000px',
        position: 'absolute',
        left: '10%',
        zIndex: '1'
      }}></div>
      <div style={{
        border: '1px dashed #999999',
        height: '2000px',
        position: 'absolute',
        left: '30%',
        zIndex: '1'
      }}></div>
      <div style={{
        border: '1px dashed #999999',
        height: '2000px',
        position: 'absolute',
        right: '30%',
        zIndex: '1'
      }}></div> */}
      {/* <div style={{
        border: '1px dashed #999999',
        height: '2000px',
        position: 'absolute',
        right: '10%',
        zIndex: '1'
      }}></div> */}
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <Section6 />
      <Section7 />
      <Footer />
    </React.Fragment>
  );
}

export default LandingPage;
