import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import instance from "../../../utils/helper/http.helper";
import RelatedNFTs from "../../__common/RelatedNFs/RelatedNFTs";
import styles from "./Section4.module.scss";
import Card from "../../DunkAuction/Card";
import pic from "../../../assets/user.svg";
import { getETHPrice } from "../../../utils/helper/ethPrice";
function Section4() {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [oneETH, setOneETH] = useState(0);
  const getApproved = async () => {
    return await instance.get("/requests?status=approved");
  };

  const getRelated = async () => {
    return await instance.get("/requests?status=approved&auction=on");
  };

  useEffect(() => {
    console.log("USing Effect");
    getETHPrice().then((res) => {
      console.log(res.data.ethereum.usd);
      setOneETH(res.data.ethereum.usd);
    });
    getApproved().then((res2) => {
      console.log(res2, "GET RESULT");
      setData([...res2.data.data]);
    });
  }, []);
  console.log(data);
  const handleRecentlySoldLike = async (id,address) => {
    
    const res = await instance.post(`requests/like/${id}`, {
      address: address,
    });
    const index = data.findIndex(obj => obj._id === id);
    let newArray=[...data]
    newArray[index]=res.data.data
    setData([...newArray]);
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <RelatedNFTs
          oneETH={oneETH}
          history={history}
          heading={"Featured"}
          boldHeading={"NFTs"}
          para={"The Hottest Sneakers NOW!"}
        />
        <h3 style={{ margin: "50px 0" }}>
          Recently Sold <b>NFTs</b>
        </h3>
        <div className={styles.flexCard}>
          {data && data.length > 0 ? (
            data.slice(0, 3).map((item) => <Card item={item} oneETH={oneETH} handleLike={handleRecentlySoldLike}/>)
          ) : (
            <div style={{ textAlign: "center", width: "100%" }}>
              No data found
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
export default Section4;
