import React from "react";
import { useHistory } from "react-router-dom";
import styles from "./Footer.module.scss";

export default function Footer() {
  const history = useHistory();
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.flexRow}>
          <div className={styles.contactUsDiv}>
            <div className={styles.contactUs}>Contact Us</div>
            <div className={styles.emailDiv}>
              <div className={styles.imgDiv}>
                <img
                  alt="img"
                  src="/assets/@.svg"
                  className={styles.passwordImg}
                />
              </div>
              IR@dunkndunk.com
            </div>
            <div className={styles.emailDiv}>
            <a href="https://twitter.com/dunk_exchange" target="_blank" className={styles.imgDiv}>
                <img
                  alt="img"
                  src="/assets/twitter.svg"
                  className={styles.passwordImg}
                />
              </a>
              <a href="https://t.me/dunkexchange" target="_blank" className={styles.imgDiv}>
                <img
                  alt="img"
                  src="/assets/plain.svg"
                  className={styles.passwordImg}
                />
              </a>
              <a href="https://dunkexchange.medium.com/" target="_blank" className={styles.imgDiv}>
                <img
                  alt="img"
                  src="/assets/medium.svg"
                  className={styles.passwordImg}
                />
              </a>
              <a href="https://www.instagram.com/dunk.exchange/" target="_blank" className={styles.imgDiv}>
                <img
                  alt="img"
                  src="/assets/instagram.svg"
                  className={styles.passwordImg}
                />
              </a>
              {/* <a href="" target="_blank" className={styles.imgDiv}>
                <img
                  alt="img"
                  src="/assets/youtube.svg"
                  className={styles.passwordImg}
                />
              </a> */}
              
            </div>
          </div>
          <div className={styles.siteMapDiv}>
            SITE MAP
            <div className={styles.imgDiv}>
              <img
                src="/assets/arrow-left-2.2.svg"
                className={styles.passwordImg}
              />
            </div>
          </div>
        </div>
        <div className={styles.separator}></div>
        <div className={styles.flexRow}>
          <div className={styles.col}>
            <div className={styles.imgDiv}>
              <img
                src="/assets/footer-logo.svg"
                className={styles.passwordImg}
              />
            </div>
            Copyright © DUNK project. All rights reserved.
          </div>
          
          <div className={styles.rightCol}>
            <div className={styles.link} onClick={() => history.push("/authentication")}>
              Authentication
            </div>
            <div className={styles.link} onClick={() => history.push("/dunk-auction")}>
              DUNK Auction
            </div>
            <div className={styles.link} onClick={() => history.push("/my-NFTs")}>My NFTs</div>
            <div className={styles.link} onClick={() => history.push("/dunk-loan")}>
              DUNK Loan
            </div>
            <div className={styles.link} onClick={() => history.push("/dunk-staking")}>Dunk Staking</div>
          </div>
        </div>
      </div>
    </div>
  );
}
