import React, { useState, useEffect } from "react";
import styles from "./DunkStaking.module.scss";
import TireBox from "./TireBox";
import { Line } from "react-chartjs-2";
import Loader from "react-loader-spinner";
import { useAlert } from "react-alert";
import {
  collectAPYReward,
  stakeToken,
  stakeTokenDetail,
  claimAPYReward,
  unStakeToken,
  getTier,
  userDetail,
  calculateAPYReward,
  userClaimableReward,
} from "../../services/NFTCreation.service";
import DunkAmountModel from "../__common/DunkAmountModel/DunkAmountModel";
import Web3 from "web3";

export default function DunkStaking() {
  const alert = useAlert();
  const [tear, setTear] = useState("0");
  const [userDetails, setUserDetails] = useState({
    amountCollected: "0",
    stakeAmount: "0",
  });
  const [isStaking, setIsStaking] = useState(false);
  const [isUnStaking, setIsUnStaking] = useState(false);
  const [isCollecting, setIsCollecting] = useState(false);
  const [isClaiming, setIsClaiming] = useState(false);

  const [isStakingOpen, setIsStakingOpen] = useState(false);
  const [collectDunkValue, setCollectDunkValue] = useState({
    reward:"0",
    _days:"0",
    totalStakeDays:"0"
  });
  const [claimDunkValue, setClaimDunkValue] = useState("");

  const [isUnStakingOpen, setIsUnStakingOpen] = useState(false);
  const [stakingPrice, setstakingPrice] = useState("0");
  const [userStaking, setUserStaking] = useState([]);
  const [allUserStaking, setAllUserStaking] = useState([]);
  const [dunkTokenDetail, setdunkTokenDetail] = useState({
    remainingStakable: "0",
    staked: "0",
    totalTokens: "0",
  });

  const stakeTokenDetailAsync = async () => {
    const res = await stakeTokenDetail();

    setdunkTokenDetail({
      remainingStakable: Web3.utils.fromWei(res.remainingStakable, "ether"),
      staked: Web3.utils.fromWei(res.staked, "ether"),
      totalTokens: Web3.utils.fromWei(res.totalTokens, "ether"),
    });
  };
  const collectAPYRewardAsync = async () => {
    try {
      setIsCollecting(true);
      const res = await collectAPYReward();
      calculateAPYRewardAsync();
      userClaimableRewardAsync();
      userDetailAsync();
      setIsCollecting(false);
      alert.show("Dunk Collected Successfully.", { type: "success" });
    } catch (error) {
      setIsCollecting(false);
      alert.show("Something went wrong.", { type: "error" });
    }
  };

  const userDetailAsync = async () => {
    const res = await userDetail();
    setUserDetails({
      amountCollected: Web3.utils.fromWei(res.amountCollected, "ether"),
      stakeAmount: Web3.utils.fromWei(res.stakeAmount, "ether"),
    });
    console.log(res);
  };

  const calculateAPYRewardAsync = async () => {
    const res = await calculateAPYReward();
debugger
    setCollectDunkValue({
      reward:Web3.utils.fromWei(res.reward, "ether"),
      _days:res._days,
      totalStakeDays:res.totalStakeDays
    });
    console.log(res);
  };

  const userClaimableRewardAsync = async () => {
    try {
      const res = await userClaimableReward();
      setClaimDunkValue(Web3.utils.fromWei(res, "ether"));
      console.log(res);
      setIsClaiming(false);
    } catch (error) {}
  };
  const claimAPYRewardAsync = async () => {
    try {
      setIsClaiming(true);
      const res = await claimAPYReward();
      calculateAPYRewardAsync();
      userClaimableRewardAsync();
      userDetailAsync();
      stakeTokenDetailAsync();
      alert.show("Dunk Claimed Successfully.", { type: "success" });
    } catch (error) {
      alert.show("Something went wrong.", { type: "error" });
      setIsClaiming(false);
    }
  };
  const getTierAsync = async () => {
    const res = await getTier();
    console.log(res);
    setTear(res);
  };

  useEffect(() => {
    calculateAPYRewardAsync();
    userClaimableRewardAsync();
    userDetailAsync();
    getTierAsync();
    stakeTokenDetailAsync();
    setUserStaking([100000,1000000, 2000000, 30000000, 40000000, 50000000]);
    setAllUserStaking([10000,2500000, 4000000, 50000000, 60000000, 70000000]);
  }, []);
  const data = {
    labels: [
      "1/1/2016",
      "1/1/2017",
      "1/1/2018",
      "1/1/2019",
      "1/1/2020",
      "1/1/2021",
    ],
    datasets: [
      {
        label: "DUNK staked from all users",
        data: userStaking,
        borderColor: ["rgb(0,157,99)"],
        backgroundColor: ["rgb(0,157,99)"],
        pointBorderColor: ["rgb(0,157,99)"],
        pointBackgroundColor: ["rgb(0,157,99)"],
      },
      {
        label: "Users staking in DUNK",
        data: allUserStaking,
        borderColor: ["rgb(211, 59, 68)"],
        backgroundColor: ["rgb(211, 59, 68)"],
        pointBorderColor: ["rgb(211, 59, 68)"],
        pointBackgroundColor: ["rgb(211, 59, 68)"],
      },
    ],
  };

  var options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
    scales: {
      y: {
        ticks: {
          callback: function (value, index, values) {
            return "$" + value;
          },
        },
      },
    },
  };
  const onStakingRequestClose = () => {
    setIsStakingOpen(!isStakingOpen);
  };
  const onUnStakingRequestClose = () => {
    setIsUnStakingOpen(!isUnStakingOpen);
  };
  const unStakeTokenAction = async () => {
    if (Number(stakingPrice) > Number(dunkTokenDetail.staked)) {
      onUnStakingRequestClose();
      alert.show("invalid amount.", { type: "error" });
    } else {
      setIsUnStaking(true);
      onUnStakingRequestClose();
      try {
        const res = await unStakeToken(stakingPrice);
        calculateAPYRewardAsync();
        userClaimableRewardAsync();
        userDetailAsync();
        stakeTokenDetailAsync();
        getTierAsync();
        console.log(res);
        setIsUnStaking(false);
        alert.show("Dunk UnStaked Successfully.", { type: "success" });
      } catch (error) {
        setIsUnStaking(false);
        alert.show("Something went wrong.", { type: "error" });
      }
    }
  };
  const stakeAction = async () => {
    debugger
    if (Number(stakingPrice) > Number(dunkTokenDetail.remainingStakable)) {
      alert.show("invalid amount.", { type: "error" });
      onStakingRequestClose();
    } else if(Number(stakingPrice) > 0) {
      setIsStaking(true);
      onStakingRequestClose();
      try {
        const res = await stakeToken(stakingPrice);
        console.log(res);
        debugger
        calculateAPYRewardAsync();
        userClaimableRewardAsync();
        userDetailAsync();
        stakeTokenDetailAsync();
        getTierAsync();
        setIsStaking(false);
        alert.show("Dunk Staked Successfully.", { type: "success" });
      } catch (error) {
        setIsStaking(false);
        alert.show("Something went wrong.", { type: "error" });
      }
    }
    else{
      alert.show("invalid amount.", { type: "error" });
      onStakingRequestClose();
    }
  };
  const handleChange = (e) => {
    setstakingPrice(e.target.value);
  };
  const stakingAPYReward = () => {
    let sum = Number(claimDunkValue) + Number(collectDunkValue.reward);
    return sum.toLocaleString("en-US", {
      minimumFractionDigits: 2,
    });
  };
  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <div className={styles.leftDiv}>
            <div className={styles.stakingDiv}>
              <div className={styles.heading}>DUNK Staking</div>
              <img src="/assets/logo.svg" />
              <div className={styles.amount}>
                {Number(dunkTokenDetail.totalTokens).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                })}
              </div>
              <div className={styles.earned}>DUNK owned</div>
              <div className={styles.amount}>
                {Number(dunkTokenDetail.staked).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                })}
              </div>
              <div className={styles.earned}>DUNK staked</div>
              <button
                disabled={isStaking ? true : false}
                className={`${styles.actionButton} ${styles.activeButton}`}
                onClick={onStakingRequestClose}
              >
                {isStaking ? (
                  <Loader type="Bars" color="white" height={30} width={40} />
                ) : (
                  "Stake >"
                )}
                {/* <img alt="img" src="/assets/arrow-left-on hover.svg" />{" "} */}
              </button>
              <button
                disabled={isUnStaking ? true : false}
                className={`${styles.actionButton} ${styles.activeButton}`}
                onClick={onUnStakingRequestClose}
              >
                {isUnStaking ? (
                  <Loader type="Bars" color="white" height={30} width={40} />
                ) : (
                  "UnStake >"
                )}
                {/* <img alt="img" src="/assets/arrow-left-on hover.svg" />{" "} */}
              </button>
            </div>
            </div>

          <div className={styles.rightDiv}>
            <div className={styles.claimCollectDiv}>
              <div className={styles.flexRow}>
                <div className={styles.stakingAPY}>Staking APY Reward</div>
                <div className={styles.percentage}>1000%</div>
              </div>
              <div className={styles.amount}>
                {stakingAPYReward()}
                {/* {Number(userDetails.amountCollected).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                })} */}
              </div>
              {/* <div className={styles.flexRow}>
                <div className={styles.preMonth}>DUNK permonth</div>
                <div className={styles.usdAmount}>$0 USD</div>
              </div> */}
              <div className={styles.flexDiv}>
                <div className={styles.card}>
                  <div className={styles.heading}>Collect DUNK
                  {/* ({collectDunkValue._days}{"  "}days) */}
                  </div>
                  <div className={styles.amount}>
                    {Number(collectDunkValue.reward).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                    })}
                  </div>
                  {/* <div className={styles.usdValue}>$0 USD</div> */}
                  <button
                    disabled={isCollecting ? true : false}
                    style={{
                      cursor: `${isCollecting ? "not-allowed" : "pointer"}`,
                    }}
                    className={`${styles.actionButton} ${styles.activeButton}`}
                    onClick={collectAPYRewardAsync}
                  >
                    {isCollecting ? (
                      <Loader
                        type="Bars"
                        color="white"
                        height={30}
                        width={40}
                      />
                    ) : (
                      "Collect >"
                    )}

                    {/* <img alt="img" src="/assets/arrow-left-on hover.svg" />{" "} */}
                  </button>
                </div>

                <div className={styles.card}>
                  <div className={styles.heading}>Claim DUNK</div>
                  <div className={styles.amount}>
                    {Number(claimDunkValue).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                    })}
                  </div>
                  {/* <div className={styles.usdValue}>$0 USD</div> */}

                  <button
                    disabled={isClaiming ? true : false}
                    style={{
                      cursor: `${isClaiming ? "not-allowed" : "pointer"}`,
                    }}
                    className={`${styles.actionButton} ${styles.activeButton}`}
                    onClick={claimAPYRewardAsync}
                  >
                    {isClaiming ? (
                      <Loader
                        type="Bars"
                        color="white"
                        height={30}
                        width={40}
                      />
                    ) : (
                      "Claim >"
                    )}
                    {/* <img alt="img" src="/assets/arrow-left-on hover.svg" />{" "} */}
                  </button>
                </div>
              </div>
              <div
                className={styles.flexRow}
                style={{
                  margin: "10px 0",
                  fontSize: "14px",
                  color: "#d33b44",
                  width: "100%",
                  justifyContent: "flex-start",
                }}
              >
                Rewards can be collected every 5 minutes!
              </div>
              <div
                className={styles.flexRow}
                style={{
                  margin: "10px 0",
                  fontSize: "14px",
                  color: "#d33b44",
                  width: "100%",
                  justifyContent: "flex-start",
                }}
              >
                There is 20% penalty on amount claim before 10 days of
                Staking.Dunk claimed after 10 days will have no penalty.
              </div>
            </div>
          
            {/* <div className={styles.tireHeading}>TIER System</div> */}
            <div className={styles.tireWrapper}>
              <div style={{fontSize:"18px"}}>Your TIER</div>          
              {tear === "2" && (
                  <TireBox
                    tire="TIER 1"
                    bg="#febabe"
                    color="#f73832"
                    value={1000}
                  />
              )}
              {tear === "1" && (
                  <TireBox
                    tire="TIER 2"
                    bg="rgba(247,165,50, 0.14)"
                    color="#f7a532"
                    value={200}
                  />
              )}
              {tear === "0" && (
                
                  <TireBox
                    tire="TIER 3"
                    bg="#e6e8ec"
                    color="#B7B7B7"
                    value={0}
                  />
              )}
            </div>
            {/* <div className={styles.graphDiv}>
              <div className={styles.heading}>DUNK Staked</div>
              <div className={styles.usd}>US$</div>
            <div className={styles.paragraph}>Acc. DUNK Token Staked</div>
              <div className={styles.flexRow}>
                <div className={styles.wrapperDiv}>
                  <div className={styles.yieldColor}></div>
                  <div className={styles.text}>DUNK staked from all users</div>
                </div>
                <div className={styles.wrapperDiv}>
                  <div className={styles.stakedColor}></div>
                  <div className={styles.text}>Users staking in DUNK</div>
                </div>
              </div>
              <div
                style={{
                  width: "900px",
                  height: "500px",
                }}
              >
                <Line data={data} options={options} />
              </div>
            </div> */}
          
          </div>
        </div>
      </div>
      <DunkAmountModel
        isOpen={isStakingOpen}
        handleConnectOpen={onStakingRequestClose}
        placeholder={"Staking Quantity"}
        handleChange={handleChange}
        currency={"Dunk"}
        handleClick={stakeAction}
      />
      <DunkAmountModel
        isOpen={isUnStakingOpen}
        handleConnectOpen={onUnStakingRequestClose}
        placeholder={"UnStaking Quantity"}
        handleChange={handleChange}
        currency={"Dunk"}
        handleClick={unStakeTokenAction}
      />
    </>
  );
}
