import axios from "axios";
import { store } from "../../index";
import instance from "../../utils/helper/http.helper";
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  VERIFY_REQUEST,
  VERIFY_SUCCESS,
  SIGNUP_FAILURE,
  SIGNUP_REQUEST,
} from "./actionTypes";
import { resetWalletsInfoAction } from "./WalletActions";

const API_URL = "https://api.dunk.exchange";
const requestLogin = () => {
  return {
    type: LOGIN_REQUEST,
  };
};
const requestSignUp = () => {
  return {
    type: SIGNUP_REQUEST,
  };
};

const receiveLogin = (token) => {
  return {
    type: LOGIN_SUCCESS,
    token,
  };
};

const loginError = (message) => {
  return {
    type: LOGIN_FAILURE,
    message,
  };
};
const signUpError = (message) => {
  return {
    type: SIGNUP_FAILURE,
    message,
  };
};

const requestLogout = () => {
  return {
    type: LOGOUT_REQUEST,
  };
};

const receiveLogout = () => {
  return {
    type: LOGOUT_SUCCESS,
  };
};

const verifyRequest = () => {
  return {
    type: VERIFY_REQUEST,
  };
};

const verifySuccess = () => {
  return {
    type: VERIFY_SUCCESS,
  };
};

export const userLogIn = (username, password) => async (dispatch) => {
  // User Log In Logic
  console.log("username/ password", username, password);
  dispatch(requestLogin());
  // localStorage.setItem("accessToken", username);
  //     dispatch(receiveLogin());
  try {
    const res = await instance.post(`/auth/login`, {
      address: username,
      password: password,
    });
    localStorage.setItem("accessToken", res.data.data.access_token);
    dispatch(receiveLogin(res.data.data.access_token));
    return res.data;
  } catch (error) {
    dispatch(loginError("Incorrect username or password"));
  }
};

export const userSignUp = (username, password) => async (dispatch) => {
  // User Log In Logic
  
  console.log("username/ password", username, password);
  dispatch(requestSignUp());
  // localStorage.setItem("accessToken", username);
  //     dispatch(receiveLogin());
  try {
    const res = await instance.post(`/auth/signup/user`, {
      address: username,
      password: password,
    });
    localStorage.setItem("accessToken", res.data.data.access_token);
    dispatch(receiveLogin(res.data.data.access_token));
    return res.data;
  } catch (error) {
    dispatch(signUpError(error.response.data.message));
  }
};

export const userLogout = () => async (dispatch) => {
  // User Log Out Logic
  await dispatch(requestLogout());
  await localStorage.clear();
  await dispatch(receiveLogout());
  await dispatch(resetWalletsInfoAction());
  // window.location.reload();
};
export const getToken = () => {
  return localStorage.getItem("accessToken");
};
export const verifyAuth = () => (dispatch) => {
  // Verify User on Reload Logic
  // dispatch(verifyRequest());
  // if (localStorage.getItem("accessToken")) {
  //   dispatch(receiveLogin());
  //   dispatch(verifySuccess());
  // } else {
  //   // dispatch(verifySuccess());
  //   dispatch(userLogout());
  // }
  dispatch(userLogout());
};
