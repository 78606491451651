import React from 'react';
import styles from './SignIn.module.scss';
import { Link } from 'react-router-dom';
import img from '../../../assets/section1.png';
import logo from '../../../assets/logo.svg';
import lock_icon from '../../../assets/lock-icon.svg'
import tick_icon from '../../../assets/tick-icon.svg';
import eye_icon from '../../../assets/eye-green-icon.svg';
const SignUp = () => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <div className={styles.flexRow}>
                    <div className={styles.leftDiv}>
                        <img src={logo} alt="Logo" />
                        <div className={styles.heading}>
                            <h3>Sign In</h3>
                            <p>Sign In with your wallet and password</p>
                        </div>
                        <div className={styles.inputFields}>
                            <div className={styles.input}>
                                <label htmlFor="wallet">Wallet</label>
                                <div className={styles.inputWrapper}>
                                    <img src={lock_icon} alt="icon" />
                                    <input type="email" name="wallet" id="wallet" />
                                    <img src={tick_icon} alt="icon" />
                                </div>
                            </div>
                            <div className={styles.input}>
                                <label htmlFor="password">Password</label>
                                <div className={styles.inputWrapper}>
                                    <img src={lock_icon} alt="icon" />
                                    <input type="password" name="password" id="password" />
                                    <img src={eye_icon} alt="icon" />
                                </div>
                            </div>
                        </div>
                        <div className={styles.btn}>
                            <Link>Forgot Password</Link>
                            <button className={styles.login}>Login</button>
                        </div>
                        <div className={styles.login}>
                            New on our platform? <span> <Link >Create an account</Link> </span>
                        </div>
                        {/* <div className={styles.loginOptions}>
                            <p>Continue with</p>
                            <div className={styles.actionBtns}>
                                <Link>Sign in with Google</Link>
                                <Link>Sign in with Facebook</Link>
                            </div>
                        </div> */}
                    </div>
                    <div className={styles.imgDiv}>
                        <img src={img} alt="Show Image" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SignUp;