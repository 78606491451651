import {
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  SIGNUP_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  VERIFY_REQUEST,
  VERIFY_SUCCESS,
} from "../actions/actionTypes";

const initialState = {
  authToken:"",
  isLoggingIn: false,
  isLoggingOut: false,
  isVerifying: false,
  loginError: false,
  logInErrorMessage:"",
  isSigningUp:false,
  signUpError:false,
  signUpErrorMessage:"",
  logoutError: false,
  isAuthenticated: false,
};

const authReducer = (state = initialState, action) => {
  console.log(action)
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        isLoggingIn: true,
        signUpError: false,
      };
      case SIGNUP_REQUEST:
      return {
        ...state,
        isSigningUp: true,
        loginError: false,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggingIn: false,
        isAuthenticated: true,
        authToken:action.token
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        isLoggingIn: false,
        isAuthenticated: false,
        loginError: true,
        logInErrorMessage:action.message,
      };
      case SIGNUP_FAILURE:
        return {
          ...state,
          isSigningUp: false,
          isAuthenticated: false,
          signUpError: true,
          signUpErrorMessage:action.message
        };
    case LOGOUT_REQUEST:
      return {
        ...state,
        isLoggingOut: true,
        logoutError: false,
        authToken:"",
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        isLoggingOut: false,
        isAuthenticated: false,
      };
    case VERIFY_REQUEST:
      return {
        ...state,
        isVerifying: true,
        verifyingError: false,
      };
    case VERIFY_SUCCESS:
      return {
        ...state,
        isVerifying: false,
      };
    default:
      return state;
  }
};

export default authReducer;
