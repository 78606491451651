import React from "react";
import { Link } from "react-router-dom";
import styles from "./Section1.module.scss";
import ShowImage from '../../../assets/section1.png';
function Section1() {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.leftDiv}>
          <div className={styles.divWrapper}>
            <h1 className={styles.tranparent}>
              Transparent
            </h1>
            <h1 className={styles.resale}>
              Resale Platform
            </h1>
            <p className={styles.quote}>
              0% Fake, 100% Safe , Aditional Services You've never experienced before
            </p>
            <Link to="dunk-auction" className={styles.gotoBtn}>GO TO GET NFT</Link>
          </div>
        </div>
        <div className={styles.rightDiv}>
          <div className={styles.imgWrapper}>
            <img src={ShowImage} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}
export default Section1;
