import React from "react";
import styles from "./Section6.module.scss";
// E:\Fiver\React\Projects\Duke\src\assets\Main Shoes.png
// E:\Fiver\React\Projects\Duke\src\components\LandingPage\Section6\Section6.jsx
import logo1 from '../../../assets/LOGOS/Mask Group 130.svg'
import logo2 from '../../../assets/LOGOS/Mask Group 131.svg'
import logo3 from '../../../assets/LOGOS/Mask Group 137.svg'
import logo4 from '../../../assets/LOGOS/Mask Group 138.svg'
import logo5 from '../../../assets/LOGOS/Mask Group 139.svg'
import logo6 from '../../../assets/LOGOS/Mask Group 140.svg'
import logo7 from '../../../assets/LOGOS/Mask Group 141.svg'
import logo8 from '../../../assets/LOGOS/Mask Group 142.svg'
import logo9 from '../../../assets/LOGOS/Mask Group 143.svg'
import logo10 from '../../../assets/LOGOS/Mask Group 144.svg'
import logo11 from '../../../assets/LOGOS/Mask Group 145.svg'
import logo12 from '../../../assets/LOGOS/Mask Group 146.svg'

function Section6() {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.colorDiv}>
         <h3> Authentication Partners</h3>
          <div className={styles.authPartnersLogo}>
            <div className={styles.imgDiv}>
              <img alt="img" src={logo1} />
            </div>
            <div className={styles.imgDiv}>
              <img alt="img" src={logo2} />
            </div>
          </div>
         <h3> OOS Partners</h3>
          <div className={styles.oosPartnersLogo}>
            <div className={styles.imgDiv}>
              <img alt="img" src={logo7} />
            </div>
            <div className={styles.imgDiv}>
              <img alt="img" src={logo6} />
            </div>
            <div className={styles.imgDiv}>
              <img alt="img" src={logo5} />
            </div>
            <div className={styles.imgDiv}>
              <img alt="img" src={logo11} />
            </div>
            <div className={styles.imgDiv}>
              <img alt="img" src={logo12} />
            </div>
            <div className={styles.imgDiv}>
              <img alt="img" src={logo4} />
            </div>
            <div className={styles.imgDiv}>
              <img alt="img" src={logo3} />
            </div>
            <div className={styles.imgDiv}>
              <img alt="img" src={logo9} />
            </div>
            <div className={styles.imgDiv}>
              <img alt="img" src={logo10} />
            </div>
            {/* <div className={styles.imgDiv}>
              <img alt="img" src={logo12} />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
export default Section6;
