import React, {useState} from 'react';
import styles from './SignUp.module.scss';
import { Link } from 'react-router-dom';
import img from '../../../assets/section1.png';
import logo from '../../../assets/logo.svg';
import eye_icon from '../../../assets/eye-green-icon.svg';
import lock_icon from '../../../assets/lock-icon.svg'
const SignUp = () => {
    const [showpassword, setShowpassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <div className={styles.flexRow}>
                    <div className={styles.leftDiv}>
                        <img src={logo} alt="Logo" />
                        <div className={styles.heading}>
                            <h3>Create Your Account</h3>
                            <p>Connect and input your password to open your account</p>
                        </div>
                        <div className={styles.inputFields}>
                            <div className={styles.input}>
                                <label htmlFor="wallet">Wallet</label>
                                <div className={styles.inputWrapper}>
                                    <img src={lock_icon} alt="icon" />
                                    <input type="wallet" name="wallet" id="wallet" />
                                </div>
                            </div>
                            <div className={styles.input}>
                                <label htmlFor="password">Password</label>
                                <div className={styles.inputWrapper}>
                                    <img src={lock_icon} alt="icon" />
                                    <input  type={showpassword? "text": "password"} name="password" id="password" />
                                    <img 
                                    src={eye_icon} 
                                    alt="icon" 
                                    onClick={() => setShowpassword(showpassword? false: true)}
                                    />
                                </div>
                            </div>
                            <div className={styles.input}>
                                <label htmlFor="confirm">Confirm Password</label>
                                <div className={styles.inputWrapper}>
                                    <img src={lock_icon} alt="icon" />
                                    <input type={showConfirmPassword? "text": "password"} name="confirm" id="confirm" />
                                    <img 
                                    src={eye_icon} 
                                    alt="icon"
                                    onClick={() => setShowConfirmPassword(showConfirmPassword? false: true)}
                                     />
                                </div>
                            </div>
                        </div>
                        <div className={styles.btn}>
                            <button className={styles.continue}>Continue</button>
                        </div>
                        <div className={styles.login}>
                            Already have an account? <span> <Link >Login</Link> </span>
                        </div>
                    </div>
                    <div className={styles.imgDiv}>
                        <img src={img} alt="Show Image" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SignUp;