import React, { useEffect } from "react";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import MyNFTsDetails from "../components/MyNFTsDetails/MyNFTsDetails";
import Section7 from "../components/LandingPage/Section7/Section7";
import Section3 from '../components/__common/Section3/Section3'
import NftDetails from "../components/__common/NftDetails/NftDetails";

export default function MyNFTsDetailsPage() {
  useEffect(()=>{
    window.scrollTo({ top: 0, behavior: 'smooth' })
},[])
  return (
    <>
    <Header/>
      
      {/* <MyNFTsDetails/> */}
      <NftDetails/>
      <Section7/>
      <Footer />
    </>
  );
}
